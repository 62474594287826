import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import { DateTime } from "luxon";

import {
  Chat,
  ChatChannel,
  ChatLog,
  ChatTab,
  ContactMerged,
  Message,
  Note,
  RealTimeEvent,
  RequestStatus,
  TicketStatus,
  User,
} from "../models";

import {
  addContact,
  assignChatToAgent,
  assignChatToCard,
  callBotmateSkill,
  chatAdapter,
  connectToChat,
  disconnectAllFromChat,
  disconnectFromChat,
  findContact,
  getAllChatsCount,
  getChat,
  getChatMessages,
  getChats,
  // getChatsRequestedCount,
  getContacts,
  getMyChats,
  getProfile,
  markChatMessagesAsRead,
  mergeContacts,
  messageAdapter,
  postConfidentialMessage,
  postCreateChatRoom,
  postImportContact,
  postPinMessage,
  postUnpinMessage,
  respondChatAssignment,
  respondVideoRequest,
  saveChatData,
  sendMessageToChat,
  sendNewMessageToChat,
  sendVideoCallRequest,
  setBlockUserStatus,
  setChatArchiveStatus,
  setChatLabels,
  setContact,
  setStarredChat,
} from "../services/chathub";
import { logError } from "../services/logging";
import i18n from "../services/localization";

import auth from "./auth";
import bots from "./bots";
import audit from "./audit";
import settings from "./settings";
import locale from "../constants/locale";
import { MutableRefObject } from "react";
import { toast } from "bulma-toast";
import workspaces from "./workspaces";
import { notification } from "antd";
import { t } from "i18next";
import { collect } from "collect.js";
import axios from "axios";

class ChatsStore {
  private db: firebase.firestore.Firestore;
  private notificationAudio = new Audio("/assets/notification.mp3");
  private unreadChatsCount: number[] = [];
  private lastChatMessage = 0;
  private lastChat = 0;
  currentTab: ChatTab = "all";

  chats: Chat[] = [];
  myChats: Chat[] = [];
  contacts: Chat[] = [];
  messages: Message[] = [];
  notes: Note[] = [];
  usersInConversation: User[] = [];
  usersInNotes: User[] = [];
  selectedChat?: Chat;
  onlineAgents: User[] = [];
  fetchingChats: boolean = true;
  fetchingMyChats: boolean = false;
  chatsAbortController: AbortController | null = null;
  chatsCount: Record<string, any> = {
    requested: 0,
    connected: 0,
  };
  ticketsCount: Record<number, number> = {};
  chat360View?: Chat;

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  loadChats = async (
    term?: string,
    label?: string,
    tab?: ChatTab,
    tabRef?: MutableRefObject<string>,
    archived?: boolean,
    pageSize?: number,
    dates?: Date[],
    sort?: string,
    channel?: ChatChannel,
    liveCardId?: number,
    nonBizHour?: number,
    priority?: number,
    currentAgentId?: string,
    campaignId?: number,
    boardId?: number,
    boardColumnId?: number,
    boardAssigneeId?: string
  ) => {
    if (!bots.selectedBot) {
      return;
    }

    this.fetchingChats = true;

    this.chatsAbortController?.abort();

    const controller = new AbortController();
    const signal = controller.signal;
    this.chatsAbortController = controller;

    this.currentTab = tab || "all";

    let liveChatFilters = auth.getPermissions()?.liveChatFilters as any;

    if (auth.userHasAdminAccess || auth.getPermissions()?.canViewAllChats) {
      liveChatFilters = undefined;
    }

    if (liveCardId) {
      liveChatFilters = {
        [liveCardId]: liveChatFilters?.[liveCardId].length
          ? liveChatFilters?.[liveCardId]
          : [
              ChatChannel.FacebookMessenger,
              ChatChannel.Instagram,
              ChatChannel.Telegram,
              ChatChannel.TwilioWhatsApp,
              ChatChannel.MetaWhatsApp,
              ChatChannel.Teams,
              ChatChannel.WebChat,
            ],
      };
    }

    let parsedDates = undefined;

    if (dates?.length === 2) {
      parsedDates = [
        DateTime.fromJSDate(dates[0])
          .setZone(bots.selectedBot.settings.timezone, {
            keepLocalTime: true,
          })
          .startOf("day")
          .toUTC()
          .toISO(),
        DateTime.fromJSDate(dates[1])
          .setZone(bots.selectedBot.settings.timezone, {
            keepLocalTime: true,
          })
          .endOf("day")
          .toUTC()
          .toISO(),
      ];
    }

    try {
      const chats = await getChats(
        signal,
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        term,
        label,
        tab,
        archived,
        pageSize,
        auth.user?.id,
        liveChatFilters,
        parsedDates,
        sort,
        channel,
        auth.user && auth.getPermissions()?.assignedCard ? auth.user.id : "",
        nonBizHour,
        priority,
        currentAgentId,
        auth.user?.type,
        campaignId,
        boardId,
        boardColumnId,
        boardAssigneeId
      );

      if (tabRef && tabRef?.current !== tab) {
        return;
      }

      this.setChats(chats);

      this.setSelectedChat(
        chats.find((f: Chat) => f.id === this.selectedChat?.id) ||
          this.selectedChat
      );
      if (tab === "requested") {
        this.loadAllChatsCount();
      }
      this.fetchingChats = false;
    } catch (error) {}
  };

  loadMyChats = async () => {
    if (!bots.selectedBot || !auth.user || this.fetchingMyChats) {
      return;
    }

    this.fetchingMyChats = true;

    try {
      const chats = await getMyChats(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        auth.user.id,
        auth.getPermissions()?.liveChatFilters
      );

      this.setMyChats(chats);

      this.setSelectedChat(
        chats.find((f: Chat) => f.id === this.selectedChat?.id) ||
          this.selectedChat
      );
    } finally {
      this.fetchingMyChats = false;
    }
  };

  handleRealTimeChatCount = async (event: RealTimeEvent) => {
    const chat = chatAdapter(event.params);

    const hasChatPermission = auth.userHasAdminAccess
      ? true
      : auth
          .getPermissions()
          ?.liveChatFilters?.[event.params.last_live_card_id]?.some(
            (s) => s === chat.channel
          );

    if (!hasChatPermission) {
      return;
    }

    let playNotification = false;

    if (event.name === "live_chat.requested") {
      playNotification = true;
      this.chatsCount = {
        ...this.chatsCount,
        requested: this.chatsCount["requested"] + 1,
        ...(chat.lastLiveCardId && {
          requestedGroups: {
            ...this.chatsCount["requestedGroups"],
            [chat.lastLiveCardId]:
              (this.chatsCount["requestedGroups"]?.[chat.lastLiveCardId] ?? 0) +
              1,
          },
        }),
      };
    } else if (
      ["live_chat.full_filled", "live_chat.timeout"].includes(event.name)
    ) {
      this.chatsCount = {
        ...this.chatsCount,
        requested: this.chatsCount["requested"] - 1,
        ...(chat.lastLiveCardId && {
          requestedGroups: {
            ...this.chatsCount["requestedGroups"],
            [chat.lastLiveCardId]:
              (this.chatsCount["requestedGroups"]?.[chat.lastLiveCardId] ?? 0) -
              1,
          },
        }),
      };
    } else if (
      event.name === "chat.updated" &&
      chat.userConnected === auth.user?.id &&
      this.selectedChat?.id !== chat.id
    ) {
      playNotification = true;
      let key = "myQueueNewMessageCount";
      if (chat?.delayedDisconnect) {
        key = "snoozedNewMessageCount";
      }
      this.chatsCount = {
        ...this.chatsCount,
        [key]: this.chatsCount[key] + 1,
      };
    } else if (event.name === "snoozed-chat.move-to-my-queue") {
      playNotification = true;
      this.chatsCount = {
        ...this.chatsCount,
        snoozedNewMessageCount: this.chatsCount["snoozedNewMessageCount"] - 1,
      };
    }

    if (playNotification) {
      await this.playNotification();
    }
  };

  handleLiveChatRequest = async (event: RealTimeEvent) => {
    const chat = chatAdapter(event.params);

    if (event.name === "live_chat.requested") {
      const name = chat.firstName + (chat.lastName ? ` ${chat.lastName}` : "");
      console.log("trigger notification");
      notification.open({
        message: t(locale.liveChatRequest) as string,
        description: t(locale.userRequestedLiveChat, {
          name,
          team: workspaces.liveChatCards?.find(
            (f) => f.id === chat.lastLiveCardId
          )?.name,
        }) as string,
      });
    }

    if (this.currentTab !== "requested") {
      return;
    }

    const hasChatPermission = auth.userHasAdminAccess
      ? true
      : auth
          .getPermissions()
          ?.liveChatFilters?.[event.params.last_live_card_id]?.some(
            (s) => s === chat.channel
          );

    if (!hasChatPermission) {
      return;
    }

    if (event.name === "live_chat.requested") {
      const chats = [...this.chats];
      const index = chats.findIndex((f) => f.id === chat.id);
      if (index > -1) {
        chats[index] = chat;
      } else {
        chats.unshift(chat);
      }
      this.setChats(chats);
    } else if (
      ["live_chat.full_filled", "live_chat.timeout"].includes(event.name)
    ) {
      this.setChats(this.chats.filter((f) => f.id !== chat.id));
    }
  };

  handleChatAssignedEvent = async (event: RealTimeEvent, assigned = true) => {
    const chat = chatAdapter(event.params);

    if (chat.userConnected === auth.user?.id) {
      if (assigned && this.currentTab === "myQueue") {
        this.setChats([chat, ...this.chats]);
      } else {
        this.setChats(this.chats.filter((f) => f.id !== chat.id));
      }
    }
  };

  handleChatTransferredEvent = async (event: RealTimeEvent) => {
    const chat = chatAdapter(event.params);

    if (event.params.previous_agent === auth.user?.id) {
      this.setChats(this.chats.filter((f) => f.id !== chat.id));
      this.setSelectedChat({
        ...chat,
        isConnected: false,
      });
    } else {
      this.handleUpdateChatEvent(event);
    }
  };

  handleSnoozedChatMoveToMyQueue = async (event: RealTimeEvent) => {
    const chat = chatAdapter(event.params);

    if (chat.userConnected === auth.user?.id) {
      if (this.currentTab === "myQueue") {
        this.setChats([chat, ...this.chats]);
      } else if (this.currentTab === "snoozed") {
        this.setChats(this.chats.filter((f) => f.id !== chat.id));
      }
    }

    //TOOD fix it later
    // await this.loadAllChatsCount();
  };

  loadAllChatsCount = async () => {
    if (!bots.selectedBot) {
      return;
    }

    let liveChatFilters = auth.getPermissions()?.liveChatFilters as any;

    if (auth.userHasAdminAccess || auth.getPermissions()?.canViewAllChats) {
      liveChatFilters = undefined;
    }

    const chats = await getAllChatsCount(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      auth?.user?.id,
      liveChatFilters
    );

    if (chats) {
      let playNotification = false;
      const lastMyQueueMessagesCount =
        this.chatsCount["myQueueNewMessageCount"] ?? 0;
      const lastSnoozedMessagesCount =
        this.chatsCount["snoozedNewMessageCount"] ?? 0;
      const lastUnassignedChatsCount = this.chatsCount["requested"] ?? 0;
      const lastAssignedChatsCount = this.chatsCount["connected"] ?? 0;
      const lastSnoozedChatsCount = this.chatsCount["snoozed"] ?? 0;

      this.chatsCount = chats;

      if (
        this.chatsCount["myQueueNewMessageCount"] > lastMyQueueMessagesCount
      ) {
        playNotification = true;
      } else if (
        this.chatsCount["snoozedNewMessageCount"] > lastSnoozedMessagesCount
      ) {
        playNotification = true;
      } else if (this.chatsCount["requested"] > lastUnassignedChatsCount) {
        playNotification = true;
      } else if (this.chatsCount["connected"] > lastAssignedChatsCount) {
        playNotification = true;
      } else if (this.chatsCount["snoozed"] > lastSnoozedChatsCount) {
        playNotification = true;
      }

      if (playNotification) {
        await this.playNotification();
      }
    }
  };

  loadContacts = async (ops?: {
    ids?: number[];
    page?: number;
    size?: number;
    order?: "asc" | "desc";
    orderBy?: string;
    term?: string;
    label?: string;
    filters?: any;
    all?: boolean;
    from?: string;
    assignee?: string;
    dateRange?: string[];
  }) => {
    if (!bots.selectedBot) {
      return;
    }

    const permission = auth.getPermissions();

    let liveChatCards = permission?.liveChatFilters;
    let collaboratorAssignedId = permission?.assignedCard ? auth?.user?.id : "";

    if (ops?.from === "contacts" && permission?.canViewAllContacts) {
      liveChatCards = undefined;
      collaboratorAssignedId = "";
    }

    if (auth.userHasAdminAccess) {
      liveChatCards = undefined;
    }

    if (ops?.assignee) {
      collaboratorAssignedId = ops.assignee;
    }

    const chats = await getContacts(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      ops?.term,
      ops?.ids,
      ops?.page,
      ops?.size,
      ops?.orderBy,
      ops?.order,
      ops?.label,
      ops?.filters,
      ops?.all,
      liveChatCards,
      collaboratorAssignedId,
      ops?.dateRange
    );

    if (ops?.all) return chats as Chat[];

    if (ops?.ids) {
      this.setContacts(chats as Chat[]);
    } else {
      this.setContacts((chats as any).data);
    }
    return chats;
  };

  mergeContact = async (
    primary: number,
    secondary: ContactMerged[],
    chatId: number = 0
  ) => {
    if (!bots.selectedBot) {
      return;
    }

    const success = await mergeContacts(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      primary,
      secondary.map((m) => m.id)
    );

    if (!success) return success;

    const cloneContacts = this.contacts
      .filter((f) => secondary.every((e) => e.id !== f.id))
      .map((m) => (m.id === primary ? { ...m, merged: secondary } : m));

    let newContacts = [];

    // when unmerged we have to pull that contact from the server
    if (chatId > 0) {
      // @ts-ignore
      newContacts = await getContacts(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        "",
        [chatId]
      );
    }

    this.setContacts([...cloneContacts, ...newContacts]);

    if (this.selectedChat?.id === primary) {
      this.setSelectedChat({ ...this.selectedChat, merged: secondary });
    }

    return success;
  };

  clearChats = () => {
    this.setChats([]);
  };

  selectChat = async (id?: number) => {
    if (!bots.selectedBot) {
      return;
    }

    this.setMessages([]);

    if (!id) {
      this.setSelectedChat(undefined);
      return;
    }

    let chat = [...this.chats, ...this.contacts].find((f) => f.id === id);

    if (!chat) {
      chat =
        (await getChat(
          bots.selectedBot.id,
          bots.selectedBot.key,
          bots.selectedBot.password,
          id
        )) || undefined;
    }

    this.setSelectedChat(chat);
  };

  loadMessages = async (pageSize?: number) => {
    if (!bots.selectedBot || !this.selectedChat) {
      return;
    }

    let liveChatFilters = auth.getPermissions()?.liveChatFilters as any;

    if (auth.userHasAdminAccess || auth.getPermissions()?.canViewAllChats) {
      liveChatFilters = undefined;
    }

    const messages = await getChatMessages(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      this.selectedChat.id,
      pageSize,
      liveChatFilters
    );

    const lastMessage =
      messages
        .slice()
        .reverse()
        .find((f) => f.isFromUser)?.id || 0;

    if (this.lastChat === this.selectedChat.id) {
      if (lastMessage !== this.lastChatMessage) {
        this.playNotification();
      }
    } else {
      this.lastChat = this.selectedChat.id;
    }
    this.lastChatMessage = lastMessage;

    this.setMessages(messages);
  };

  markAsRead = async () => {
    if (
      !bots.selectedBot ||
      !this.selectedChat ||
      document.visibilityState !== "visible"
    ) {
      return;
    }

    await markChatMessagesAsRead(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      this.selectedChat.id
    );
  };

  loadUsersInConversation = async () => {
    if (!this.messages.length) {
      return;
    }

    try {
      const usersIdsSet = new Set<string>();
      this.messages.forEach((f) => f.senderId && usersIdsSet.add(f.senderId));
      const usersIdsArray = [...usersIdsSet];

      const users: User[] = [];

      for (let index = 0; index < Math.ceil(usersIdsSet.size / 10); index++) {
        const snap = await this.db
          .collection("users")
          .where("id", "in", usersIdsArray.slice(index * 10, (index + 1) * 10))
          .get();

        users.push(...snap.docs.map((m) => m.data() as User));
      }

      this.setUsersInConversation(users);
    } catch (error) {
      logError(error);
    }
  };

  connect = async (chatId?: undefined) => {
    const cId = chatId ?? this.selectedChat?.id;
    if (!bots.selectedBot || !cId || !auth.user) {
      return;
    }

    try {
      const connected = await connectToChat(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        cId,
        auth.user
      );

      if (!connected) {
        return;
      }

      this.setChats(
        this.chats.map((m) =>
          m.id === cId
            ? { ...m, isConnected: true, userConnected: auth.user?.id }
            : m
        )
      );

      if (this.selectedChat && this.selectedChat?.id === cId) {
        if (bots.selectedBot?.settings?.autoOpenChat360ModalWhenAgentConnects) {
          this.setChat360View(this.selectedChat.id);
        }
        this.setSelectedChat({
          ...this.selectedChat,
          isConnected: true,
          userConnected: auth.user.id,
        });
      }
    } catch (error) {
      logError(error);
    }
  };

  setSelectedChatDisconnect = async (isConnected: boolean) => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return;
    }
    this.setSelectedChat({
      ...this.selectedChat,
      isConnected,
      hasRequestedAgent: false,
    });

    this.setChats(
      this.chats
        .filter((m) => {
          if (this.currentTab === "all") {
            return true;
          }
          return m.id !== this.selectedChat?.id;
        })
        .map((m) =>
          m.id === this.selectedChat?.id ? { ...m, isConnected } : m
        )
    );
  };

  disconnect = async (
    delayedDisconnect: number,
    skipClosingDialog?: boolean
  ) => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return;
    }

    try {
      const disconnected = await disconnectFromChat(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        auth.user,
        delayedDisconnect,
        skipClosingDialog
      );

      if (!disconnected) {
        return;
      }

      this.setSelectedChatDisconnect(delayedDisconnect !== 0);
    } catch (error) {
      logError(error);
    }
  };

  disconnectAll = async (dates?: Date[], liveCardId?: number) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    try {
      let parsedDates = undefined;

      if (dates?.length === 2) {
        parsedDates = [
          DateTime.fromJSDate(dates[0])
            .setZone(bots.selectedBot.settings.timezone, {
              keepLocalTime: true,
            })
            .toUTC()
            .toISO(),
          DateTime.fromJSDate(dates[1])
            .setZone(bots.selectedBot.settings.timezone, {
              keepLocalTime: true,
            })
            .toUTC()
            .toISO(),
        ];
      }

      await disconnectAllFromChat(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        parsedDates ? undefined : this.chats.map((m) => m.id),
        parsedDates ? parsedDates : undefined,
        liveCardId
      );
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  pinMessage = async (messageId: number, duration: number) => {
    if (!bots.selectedBot || !this.selectedChat) {
      return;
    }

    try {
      const chat = await postPinMessage(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        messageId,
        duration
      );
      if (chat) {
        this.setSelectedChat({
          ...this.selectedChat,
          ...chat,
        });
      }
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  unpinMessage = async () => {
    if (!bots.selectedBot || !this.selectedChat) {
      return;
    }

    try {
      await postUnpinMessage(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id
      );
      this.setSelectedChat({
        ...this.selectedChat,
        data: {
          ...this.selectedChat.data,
          pinMessage: "",
        },
      });
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  confidentialMessage = async (messageId: number, remove?: boolean) => {
    if (!bots.selectedBot || !this.selectedChat) {
      return;
    }

    let liveChatFilters = auth.getPermissions()?.liveChatFilters as any;

    if (auth.userHasAdminAccess || auth.getPermissions()?.canViewAllChats) {
      liveChatFilters = undefined;
    }

    try {
      const success = await postConfidentialMessage(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        messageId,
        remove,
        liveChatFilters
      );
      if (success) {
        this.setMessages(
          this.messages.map((m) =>
            m.id === messageId ? { ...m, confidential: !remove } : m
          )
        );
      }
    } catch (e) {
      throw e;
    }
  };

  sendNewMessage = async (
    senderId: string,
    liveCardId: number,
    templateMeta: Record<string, any>,
    saveAsContact?: boolean,
    firstName?: string,
    lastName?: string,
    type?: string
  ) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    try {
      return await sendNewMessageToChat(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        senderId,
        liveCardId,
        templateMeta,
        auth.user.id,
        saveAsContact,
        firstName,
        lastName,
        type
      );
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  sendMessage = async (message: string | Blob) => {
    if (!bots.selectedBot || !this.selectedChat?.id || !auth.user) {
      return;
    }

    try {
      const sent = await sendMessageToChat(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat?.id,
        auth.user.id,
        message
      );

      if (!sent) {
        return;
      }

      // this.setSelectedChat({
      //   ...this.selectedChat,
      //   updatedAt: new Date(),
      // });

      // this.setMessages([...this.messages, ...sent]);

      // this.setChats(
      //   this.chats.map((m) =>
      //     m.id === this.selectedChat?.id ? { ...m, updatedAt: new Date() } : m
      //   )
      // );
      // this.setMyChats(
      //   this.myChats.map((m) =>
      //     m.id === this.selectedChat?.id ? { ...m, updatedAt: new Date() } : m
      //   )
      // );
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  saveData = async (data: any) => {
    if (!bots.selectedBot || !this.selectedChat) {
      return;
    }

    try {
      const updated = await saveChatData(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        data
      );

      if (!updated) {
        return;
      }

      const changed = Object.keys(data).filter(
        (f) =>
          data[f] !== this.selectedChat?.data?.[f] &&
          (this.selectedChat?.data?.[f] === undefined ? data[f] !== "" : true)
      );
      if (changed.length) {
        await this.addChatLog(this.selectedChat.id, {
          type: "data",
          data: changed.reduce(
            (p, n) => ({ ...p, [n]: [this.selectedChat?.data?.[n], data[n]] }),
            {}
          ),
        });
      }

      Object.entries(data).forEach(([k, v]) => {
        if (typeof v !== "string") {
          data[k] = JSON.stringify(v);
        }
      });

      this.setSelectedChat({
        ...this.selectedChat,
        updatedAt: new Date(),
        data: { ...this.selectedChat.data, ...data },
        firstName: data.first_name || this.selectedChat.firstName,
        lastName: data.last_name || this.selectedChat.lastName,
      });

      this.setChats(
        this.chats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                updatedAt: new Date(),
                data: { ...m.data, ...data },
                firstName: data.first_name || m.firstName,
                lastName: data.last_name || m.lastName,
              }
            : m
        )
      );

      this.setContacts(
        this.contacts.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                updatedAt: new Date(),
                data: { ...m.data, ...data },
                firstName: data.first_name || m.firstName,
                lastName: data.last_name || m.lastName,
              }
            : m
        )
      );

      this.setMyChats(
        this.myChats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                updatedAt: new Date(),
                data: { ...m.data, ...data },
                firstName: data.first_name || m.firstName,
                lastName: data.last_name || m.lastName,
              }
            : m
        )
      );
    } catch (error) {
      logError(error);
    }
  };

  markAsContact = async (chatId: number) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    try {
      const updated = await setContact(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        chatId,
        true
      );

      if (!updated) {
        return;
      }

      await this.addChatLog(chatId, {
        type: true ? "set_contact" : "remove_contact",
      });
    } catch (error) {
      logError(error);
    }
  };

  saveContactData = async (chatId: number, data: any) => {
    if (!bots.selectedBot) {
      return;
    }

    try {
      await saveChatData(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        chatId,
        data
      );
    } catch (error) {
      logError(error);
    }
  };

  setAsContact = async (value: boolean) => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return;
    }

    try {
      const updated = await setContact(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        value
      );

      if (!updated) {
        return;
      }

      await this.addChatLog(this.selectedChat.id, {
        type: value ? "set_contact" : "remove_contact",
      });

      this.setSelectedChat({
        ...this.selectedChat,
        updatedAt: new Date(),
        isContact: value,
      });

      if (value) {
        this.setContacts([...this.contacts, this.selectedChat]);
      } else {
        this.setContacts(
          this.contacts.filter((f) => f.id !== this.selectedChat?.id)
        );
      }

      this.setChats(
        this.chats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                updatedAt: new Date(),
                isContact: value,
              }
            : m
        )
      );

      this.setMyChats(
        this.myChats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                updatedAt: new Date(),
                isContact: value,
              }
            : m
        )
      );
    } catch (error) {
      logError(error);
    }
  };

  setBlockStatus = async (value: boolean) => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return;
    }

    try {
      const updated = await setBlockUserStatus(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        value
      );

      if (!updated) {
        return;
      }

      await this.addChatLog(this.selectedChat.id, {
        type: value ? "blocked" : "unblocked",
      });

      this.setSelectedChat({
        ...this.selectedChat,
        updatedAt: new Date(),
        isBlocked: value,
      });

      if (value) {
        this.setContacts([...this.contacts, this.selectedChat]);
      } else {
        this.setContacts(
          this.contacts.filter((f) => f.id !== this.selectedChat?.id)
        );
      }

      this.setChats(
        this.chats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                updatedAt: new Date(),
                isBlocked: value,
              }
            : m
        )
      );

      this.setMyChats(
        this.myChats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                updatedAt: new Date(),
                isBlocked: value,
              }
            : m
        )
      );
    } catch (error) {
      logError(error);
    }
  };

  refreshProfile = async () => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return;
    }

    try {
      const updated = await getProfile(
        bots.selectedBot.id,
        this.selectedChat.id
      );

      if (!updated) {
        return;
      }

      await this.addChatLog(this.selectedChat.id, {
        type: "profile_update",
      });

      this.setSelectedChat({
        ...this.selectedChat,
        ...updated,
        updatedAt: new Date(),
      });

      this.setChats(
        this.chats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                ...updated,
                updatedAt: new Date(),
              }
            : m
        )
      );

      this.setMyChats(
        this.myChats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                ...updated,
                updatedAt: new Date(),
              }
            : m
        )
      );
    } catch (error) {
      logError(error);
    }
  };

  existsContact = async (id: string, channel: ChatChannel) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    return await findContact(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      id,
      channel
    );
  };

  newContact = async (data: {
    firstName: string;
    lastName: string;
    id?: string;
    channel?: ChatChannel;
    otherChannel?: string;
  }) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    try {
      const chat = await addContact(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        data.firstName,
        data.lastName,
        data.id,
        data.channel,
        data.otherChannel
      );

      if (!chat) {
        return;
      }

      await this.addChatLog(chat.id, {
        type: "set_contact",
      });

      this.setContacts([...this.contacts, chat]);
    } catch (error) {
      logError(error);
    }
  };

  createChatRoom = async (data: any) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    try {
      const chat = await postCreateChatRoom(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        data
      );

      if (!chat) {
        return;
      }

      this.setChats([chat, ...this.chats]);
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  importContact = async (file: File, fields: any, labelsMap: any) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    try {
      await postImportContact(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        file,
        fields,
        labelsMap
      );

      audit.logEvent("contact_imported", {
        botId: bots.selectedBot.id,
      });
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  addChatLog = async (
    id: number | string,
    log: {
      type: ChatLog["type"];
      data?: ChatLog["data"];
    }
  ) => {
    if (!bots.selectedBot || !auth.user) {
      return [];
    }

    try {
      await this.db
        .collection("bots")
        .doc(String(bots.selectedBot.id))
        .collection("chats")
        .doc(String(id))
        .collection("log")
        .add({
          ...log,
          createdBy: auth.user.id,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
      logError(error);
    }
  };

  getChatLog = async (id: number) => {
    if (!bots.selectedBot) {
      return undefined;
    }

    const snap = await this.db
      .collection("bots")
      .doc(String(bots.selectedBot.id))
      .collection("chats")
      .doc(String(id))
      .collection("log")
      .orderBy("createdAt", "desc")
      .get();

    const logs = snap.docs.map((m) => m.data() as ChatLog);

    const usersIdsSet = new Set<string>();
    logs.forEach((f) => {
      usersIdsSet.add(f.createdBy);
      if (f.type === "data" && f.data?.assignee) {
        f.data.assignee[0] && usersIdsSet.add(f.data.assignee[0]);
        f.data.assignee[1] && usersIdsSet.add(f.data.assignee[1]);
      }
    });
    const usersIdsArray = [...usersIdsSet];

    const users: { [id: string]: User } = {};

    for (let index = 0; index < Math.ceil(usersIdsSet.size / 10); index++) {
      const snap = await this.db
        .collection("users")
        .where("id", "in", usersIdsArray.slice(index * 10, (index + 1) * 10))
        .get();

      snap.docs.forEach((f) => {
        const data = f.data() as User;
        users[data.id] = data;
      });
    }

    return { logs, users };
  };

  setLabels = async (
    label: string,
    type: "add" | "remove",
    deadline?: string
  ) => {
    if (!bots.selectedBot || !this.selectedChat) {
      return;
    }

    try {
      const chat = await setChatLabels(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        label,
        type,
        deadline
      );
      if (chat) {
        this.setSelectedChat({
          ...this.selectedChat,
          ...chat,
        });

        this.setChats(
          this.chats.map((m) => (m.id === this.selectedChat?.id ? chat : m))
        );
      }
    } catch (error) {
      logError(error);
    }
  };

  setArchiveStatus = async (archive: boolean) => {
    if (!bots.selectedBot || !this.selectedChat) {
      return;
    }

    try {
      const updated = await setChatArchiveStatus(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        archive
      );

      if (!updated) {
        return;
      }

      this.setSelectedChat({
        ...this.selectedChat,
        updatedAt: new Date(),
        archived: archive,
      });

      if (archive) {
        this.setChats(this.chats.filter((m) => m.id !== this.selectedChat?.id));
      } else {
        this.setChats(
          this.chats.map((m) =>
            m.id === this.selectedChat?.id
              ? {
                  ...m,
                  updatedAt: new Date(),
                  archived: archive,
                }
              : m
          )
        );
      }
    } catch (error) {
      logError(error);
    }
  };

  loadPrivateNotes = async () => {
    if (!bots.selectedBot || !this.selectedChat) {
      return;
    }

    try {
      this.setNotes([]);

      const snap = await this.db
        .collection("bots")
        .doc(String(bots.selectedBot.id))
        .collection("chats")
        .doc(String(this.selectedChat.id))
        .collection("notes")
        .get();

      this.setNotes(
        snap.docs.map((m) => {
          const note = m.data() as Note;

          note.updatedAt = (note.updatedAt as any).toDate();
          note.createdAt = (note.createdAt as any).toDate();

          return note;
        })
      );
    } catch (error) {
      logError(error);
    }
  };

  addPrivateNote = async (content: string) => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return;
    }

    try {
      const doc = this.db
        .collection("bots")
        .doc(String(bots.selectedBot.id))
        .collection("chats")
        .doc(String(this.selectedChat.id))
        .collection("notes")
        .doc();

      const note: Note = {
        id: doc.id,
        content,
        createdBy: auth.user.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };

      await doc.set(note);

      note.createdAt = new Date();
      note.updatedAt = new Date();

      this.setNotes([...this.notes, note]);
      this.setUsersInNotes(
        this.usersInNotes.some((s) => s.id === auth.user?.id)
          ? this.usersInNotes
          : [...this.usersInNotes, auth.user]
      );
    } catch (error) {
      logError(error);
    }
  };

  loadUsersInNotes = async () => {
    if (!this.notes.length) {
      return;
    }

    try {
      const usersIdsSet = new Set<string>();
      this.notes.forEach((f) => usersIdsSet.add(f.createdBy));
      const usersIdsArray = [...usersIdsSet];

      const users: User[] = [];

      for (let index = 0; index < Math.ceil(usersIdsSet.size / 10); index++) {
        const snap = await this.db
          .collection("users")
          .where("id", "in", usersIdsArray.slice(index * 10, (index + 1) * 10))
          .get();

        users.push(...snap.docs.map((m) => m.data() as User));
      }

      this.setUsersInNotes(users);
    } catch (error) {
      logError(error);
    }
  };

  loadOnlineAgents = async () => {
    if (!bots.selectedBot || !workspaces.selectedWorkspace) {
      return;
    }

    this.setOnlineAgents([]);

    try {
      const snap = await this.db
        .collection("users")
        .where("lastActivityOnBot", "==", bots.selectedBot.id)
        .where(
          "lastActivity",
          ">=",
          DateTime.utc().minus({ hours: 3 }).toJSDate()
        )
        .get();

      let temp: User[] = [];

      snap.docs.forEach((doc) => {
        const data = doc.data() as User;
        if (
          data.id !== auth.user?.id &&
          !data.permissions?.perWorkspace?.[
            String(workspaces.selectedWorkspace?.id)
          ]?.userDeactivated
        )
          temp.push(data);
      });

      this.setOnlineAgents(temp);
    } catch (error) {
      logError(error);
    }
  };

  assignToCard = async (cardId: string, chatId: number) => {
    if (!bots.selectedBot || !auth.user?.id) {
      return;
    }

    const result = await assignChatToCard(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      auth.user.id,
      cardId,
      chatId
    );

    if (this.currentTab === "myQueue") {
      this.setChats(this.chats.filter((m) => m.id !== chatId));
      if (this.selectedChat?.id === chatId) {
        this.setSelectedChat({
          ...this.selectedChat,
          userConnected: undefined,
          isConnected: false,
        });
      }
    }

    return result;
  };

  assignToAgent = async (agentId: string, chatId: number) => {
    if (!bots.selectedBot || !auth.user?.id) {
      return;
    }

    const autoAssign =
      bots.selectedBot.settings.enableAutomaticChatAcceptance || false;

    let userToConnect = undefined;

    if (autoAssign) {
      const userData = await this.db.collection("users").doc(agentId).get();

      userToConnect = userData.data() as User;
    }

    const result = await assignChatToAgent(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      auth.user.id,
      agentId,
      chatId,
      autoAssign,
      userToConnect
    );

    if (result) {
      if (autoAssign) {
        this.setMyChats(this.myChats.filter((m) => m.id !== chatId));
      } else {
        const buildNewChat = (chat: Chat) => ({
          ...chat,
          requestStatus: "sent" as RequestStatus,
          userConnected: agentId,
          previousUserConnected: chat.userConnected,
        });
        this.setChats(
          this.chats.map((m) => (m.id === chatId ? buildNewChat(m) : m))
        );
        this.setMyChats(
          this.myChats.filter((m) => (m.id === chatId ? buildNewChat(m) : m))
        );
        audit.logEvent("user_assigned_chat", {
          userId: auth.user?.id,
          agentId,
          chatId,
        });
      }
    }

    return result;
  };

  respondToAssignment = async (
    chatId: number,
    status: "rejected" | "accepted"
  ) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    const result = await respondChatAssignment(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      auth.user.id,
      chatId,
      status,
      auth.user
    );

    if (result) {
      if (this.currentTab === "myQueue" && status === "rejected") {
        this.setChats(this.chats.filter((m) => m.id !== chatId));
        audit.logEvent("user_rejected_assigned_chat", {
          agentId: auth.user.id,
          chatId,
        });
      } else {
        this.setChats(
          this.chats.map((m) =>
            m.id === chatId ? { ...m, requestStatus: status } : m
          )
        );

        if (this.selectedChat?.id === chatId) {
          this.setSelectedChat({
            ...this.selectedChat,
            requestStatus: status,
            userConnected: auth.user?.id,
            isConnected: true,
          });
        }
        audit.logEvent("user_accepted_assigned_chat", {
          agentId: auth.user.id,
          chatId,
        });
      }
    }

    return result;
  };

  sendVideoRequest = async () => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return false;
    }

    if (bots.isLowBalance()) {
      toast({
        message: "Balance is low please recharge your account",
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
      return true;
    }

    try {
      const message = await sendVideoCallRequest(
        bots.selectedBot.id,
        auth.user.id,
        this.selectedChat.id
      );

      if (!message) {
        return false;
      }

      this.setSelectedChat({
        ...this.selectedChat,
        disableVideoCall: true,
      });

      this.setChats(
        this.chats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                disableVideoCall: true,
              }
            : m
        )
      );
      this.setMyChats(
        this.myChats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                disableVideoCall: true,
              }
            : m
        )
      );
      this.setMessages([...this.messages, message]);

      return true;
    } catch (error) {
      logError(error);
      return false;
    }
  };

  toggleStarredChat = async (value = false, priority?: number) => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return false;
    }

    try {
      if (value && this.selectedChat?.priority === priority) {
        value = false;
        priority = undefined;
      }
      const succeeded = await setStarredChat(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        this.selectedChat.id,
        value,
        priority
      );

      if (!succeeded) {
        return false;
      }

      this.setSelectedChat({
        ...this.selectedChat,
        starred: value,
        priority,
      });

      this.setChats(
        this.chats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                starred: value,
                priority,
              }
            : m
        )
      );
      this.setMyChats(
        this.myChats.map((m) =>
          m.id === this.selectedChat?.id
            ? {
                ...m,
                starred: value,
                priority,
              }
            : m
        )
      );

      return true;
    } catch (error) {
      logError(error);
      return false;
    }
  };

  respondToVideo = async (messageId: number, status: RequestStatus) => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return false;
    }

    try {
      const message = await respondVideoRequest(
        bots.selectedBot.id,
        messageId,
        status
      );

      if (!message) {
        return false;
      }

      this.setMessages(
        this.messages.map((m) => (m.id === messageId ? message : m))
      );

      return message;
    } catch (error) {
      logError(error);
      return false;
    }
  };

  setChats = (chats: Chat[]) => {
    this.chats = collect(chats).unique("id").values().all() as Chat[];
  };

  setMyChats = (myChats: Chat[]) => {
    this.myChats = myChats || [];
    this.updateChatCount();
  };

  setContacts = (contacts: Chat[]) => {
    this.contacts = contacts;
  };

  setMessages = (messages: Message[]) => {
    this.messages = messages;
  };

  setNotes = (notes: Note[]) => {
    this.notes = notes;
  };

  setUsersInConversation = (usersInConversation: User[]) => {
    this.usersInConversation = usersInConversation;
  };

  setUsersInNotes = (usersInNotes: User[]) => {
    this.usersInNotes = usersInNotes;
  };

  setSelectedChat = (selectedChat?: Chat) => {
    this.selectedChat = selectedChat;
  };

  setOnlineAgents = (onlineAgents: User[]) => {
    this.onlineAgents = onlineAgents;
  };

  botmateSkill = async (skill: string, value: string | undefined) => {
    if (!bots.selectedBot || !this.selectedChat || !auth.user) {
      return false;
    }

    let messages: any[] = [];

    if (skill !== "tone-and-grammar-review") {
      messages = [...this.messages]
        .reverse()
        .slice(0, 50)
        .map((message) => {
          return {
            role: message.isFromUser ? "user" : "agent",
            content: message.text,
          };
        });
    } else if ((value?.replace("=", "")?.length || 0) < 3) {
      return false;
    }

    try {
      const message = await callBotmateSkill(
        bots.selectedBot.id,
        skill,
        value?.replace("=", ""),
        messages
      );

      if (!message) {
        throw new Error("Something went wrong.");
      }

      return message;
    } catch (error) {
      logError(error);
      if (error instanceof Error) {
        toast({
          message: error.message,
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
      return false;
    }
  };

  handleUpdateChatEvent = async (event: RealTimeEvent) => {
    const chat = chatAdapter(event.params);

    if (chat) {
      if (chat.isContact) {
        this.setContacts(
          this.contacts.map((m) => (m.id === chat.id ? chat : m))
        );
      }
      this.setChats(
        this.chats.map((m) =>
          m.id === chat.id
            ? {
                ...chat,
                newMessagesCount:
                  this.selectedChat?.id === chat.id ? 0 : chat.newMessagesCount,
              }
            : m
        )
      );
      this.setMyChats(this.myChats.map((m) => (m.id === chat.id ? chat : m)));
      if (this.selectedChat?.id === chat.id) {
        this.setSelectedChat(chat);
      }
    }
  };

  handleAgentConnectedEvent = async (event: RealTimeEvent) => {
    const chat = chatAdapter(event.params);

    if (
      this.currentTab === "myQueue" &&
      chat?.userConnected === auth.user?.id
    ) {
      const index = this.chats.findIndex((m) => m.id === chat.id);
      if (index === -1) {
        this.setChats([chat, ...this.chats]);
      }
    }
  };

  handleNewMessageEvent = async (event: RealTimeEvent) => {
    const message = messageAdapter(event.params);

    if (message) {
      if (this.selectedChat?.id === event.params.subscribers_id) {
        this.setMessages([...this.messages, message]);
      }
    }
  };

  updateMyQueueCount = (id: number) => {
    const chat = this.chats.find((m) => m.id === id);
    if (chat?.newMessagesCount) {
      let key = "myQueueNewMessageCount";
      if (chat?.delayedDisconnect) {
        key = "snoozedNewMessageCount";
      }

      this.chatsCount = {
        ...this.chatsCount,
        [key]: Math.max(this.chatsCount[key] - chat?.newMessagesCount, 0),
      };

      this.setChats(
        this.chats.map((m) => (m.id === id ? { ...m, newMessagesCount: 0 } : m))
      );
    }
  };

  setChat360View = (chatId?: number) => {
    this.chat360View = this.chats.find((m) => m.id === chatId);
  };

  loadTicketsCount = async () => {
    if (!bots.selectedBot || !auth.user?.id) return;
    const counts: Record<number, number> = {};

    const liveChards = workspaces.activeLiveChatCards?.map((f) => f.id);

    for (const card of liveChards) {
      let snapshot = await firebase
        .firestore()
        .collection("bots")
        .doc(String(bots.selectedBot.id))
        .collection("tasks")
        .where("liveCardId", "==", card)
        .where("assigneeId", "==", auth.user?.id)
        .where("status", "in", [TicketStatus.TODO, TicketStatus.IN_PROGRESS])
        .orderBy("createdAt", "desc")
        .get();
      counts[card] = snapshot.size;
    }

    this.ticketsCount = counts;
  };

  private updateChatCount = async () => {
    const count: number[] = [];

    if (settings.showMyChatsNotifications) {
      this.myChats.forEach((p) => {
        if (p.newMessagesCount) {
          count.push(p.id);
        }
      });
    }

    // if (settings.showRequestedChatsNotifications && bots.selectedBot) {
    //   const requested = await getChatsRequestedCount(
    //     bots.selectedBot.id,
    //     bots.selectedBot.key,
    //     bots.selectedBot.password,
    //     auth.getPermissions()?.liveChatFilters
    //   );
    //   count.push(...requested);
    // }

    if (count.length) {
      document.title = `(${count.length}) EDNA Platform`;
    } else {
      document.title = "EDNA Platform";
    }

    let counter = 0;
    this.unreadChatsCount.forEach((m) => {
      if (count.includes(m)) {
        counter++;
      }
    });

    this.unreadChatsCount = count;

    let countLength = count.length;
    if (this.selectedChat && count.includes(this.selectedChat.id)) {
      --countLength;
    }

    if (counter < countLength) {
      await this.playNotification();
    }
  };

  private playNotification = async () => {
    this.notificationAudio.play().catch(function (error) {
      console.log("Chrome cannot play sound without user interaction first");
    });

    if (document.visibilityState === "visible") {
      return;
    }

    try {
      await Notification.requestPermission();
      new Notification("EDNA Platform", {
        body: i18n.t(locale.newMessageReceived),
      });
    } catch (error) {
      logError(error);
    }
  };

  loadContactSegments = async (filters?: Record<string, any>) => {
    if (!bots.selectedBot) {
      return;
    }
    const {
      data: { response },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/contact-segment`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          ...filters,
        },
      }
    );

    return response;
  };

  createContactSegment = async (segment: any): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    const {
      data: { response: data },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/contact-segment`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        ...segment,
        created_by: auth.user?.id,
      }
    );

    return data;
  };

  updateContactSegment = async (segment: any): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/contact-segment/${segment.id}`,
        {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          ...segment,
          updated_by: auth.user?.id,
        }
      );
    } catch (error) {
      throw error;
    }
  };

  deleteContactSegment = async (id: number): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/contact-segment/${id}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );
  };
}

export default new ChatsStore();
