import { useState, useEffect, useCallback } from "react";
import { Modal } from "../..";
import { FaPhoneAlt, FaBackspace, FaMinus, FaExpand } from "react-icons/fa";
import { Duration } from "luxon";
import { Chat } from "../../../models";
import { toast } from "bulma-toast";
import locale from "../../../constants/locale";
import { useStore } from "../../../stores";
import { useTranslation } from "react-i18next";

type CallModalProps = {
  callerId?: string;
  isInProgress?: boolean;
  onHangUp: () => void;
  mode: "inbound" | "outbound";
};

export default function VoiceCallModal({
  isInProgress = false,
  onHangUp,
  callerId,
}: CallModalProps) {
  const [seconds, setSeconds] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  const { setVoiceCallMeta } = useStore("bots");

  useEffect(() => {
    if (isInProgress) {
      const timer = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isInProgress]);

  const MinimizedView = () => (
    <div
      className="card is-floating"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        width: "300px",
        zIndex: 1000,
        boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
      }}
    >
      <div className="card-content p-3">
        <div className="is-flex is-justify-content-space-between is-align-items-center">
          <div className="is-flex is-align-items-center">
            <div
              className="is-flex is-justify-content-center is-align-items-center has-background-success-light mr-3"
              style={{ width: "32px", height: "32px", borderRadius: "50%" }}
            >
              <FaPhoneAlt className="has-text-success" size={16} />
            </div>
            <div>
              <p className="has-text-weight-bold is-size-6">
                {isInProgress ? callerId : "New Call"}
              </p>
              {isInProgress && (
                <p className="is-size-7">
                  {Duration.fromObject({ second: seconds }).toFormat("mm:ss")}
                </p>
              )}
            </div>
          </div>
          <div className="is-flex is-align-items-center">
            <button
              className="button is-small is-ghost mr-2"
              onClick={() => setIsMinimized(false)}
            >
              <FaExpand size={14} />
            </button>
            <button
              className="button has-text-white is-danger is-rounded is-small"
              onClick={onHangUp}
            >
              <span style={{ position: "absolute" }} className="icon">
                <FaPhoneAlt size={12} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  if (isMinimized) {
    return <MinimizedView />;
  }

  return (
    <Modal
      isOpen
      title={
        isInProgress
          ? () => (
              <div className="is-align-items-center is-flex is-justify-content-space-between w-100">
                <h2 className="title is-5 mb-0">In-Progress Call</h2>
                <button
                  className="button is-small"
                  onClick={() => setIsMinimized(true)}
                  style={{
                    height: 16,
                    width: 34,
                  }}
                >
                  <FaMinus size={14} />
                </button>
              </div>
            )
          : "Dial Pad"
      }
      onClose={!isInProgress ? () => setVoiceCallMeta(undefined) : undefined}
    >
      {isInProgress ? (
        <InProgressCall
          callerId={callerId}
          onHangUp={onHangUp}
          seconds={seconds}
        />
      ) : (
        <NewCallDialPad />
      )}
    </Modal>
  );
}

export const NewCallDialPad = ({
  defaultNumber,
  onClosed,
}: {
  defaultNumber?: string;
  onClosed?: () => void;
}) => {
  const { t } = useTranslation();
  const { setVoiceCallMeta, twilioCallDevice } = useStore("bots");
  const { sendNewMessage } = useStore("chats");
  const [loading, setLoading] = useState(false);
  const [dialNumber, setDialNumber] = useState(defaultNumber ?? "");
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleKeyPress = (key: string) => {
    if (dialNumber.length < 15) {
      setDialNumber((prev) => prev + key);
    }
  };

  const handleBackspace = () => {
    setDialNumber((prev) => prev.slice(0, -1));
  };

  const onCall = async () => {
    setLoading(true);
    try {
      (await sendNewMessage(
        dialNumber,
        0,
        {},
        false,
        dialNumber,
        " ",
        "call"
      )) as Chat;
      const outgoingConnection = twilioCallDevice.connect({
        To: dialNumber,
      });
      const accept = () =>
        new Promise((resolve, reject) => {
          const timeout = setTimeout(() => {
            reject("timeout");
          }, 10000);

          outgoingConnection.on("accept", (conn: any) => {
            clearTimeout(timeout); // Clear timeout if call is accepted
            console.log("accept", conn);
            resolve(outgoingConnection);
          });

          outgoingConnection.on("error", (error: any) => {
            clearTimeout(timeout);
            console.error("Connection error:", error);
            reject(error);
          });

          outgoingConnection.on("disconnect", (conn: any) => {
            clearTimeout(timeout);
            console.log("Call disconnected:", conn);
          });
        });
      await accept();
      onClosed && onClosed();
      setVoiceCallMeta({
        callerId: dialNumber,
        mode: "outbound",
        inProgress: true,
        conn: outgoingConnection,
      });
    } catch (error: any) {
      console.log("error", error);
      toast({
        message: t(locale.genericError),
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleZeroButtonDown = useCallback(() => {
    // Start a timer when button is pressed
    const timer = setTimeout(() => {
      // If the button is still pressed after 500ms, add a plus sign
      if (dialNumber.length === 0) {
        setDialNumber("+");
      }
    }, 500);
    setLongPressTimer(timer);
  }, [dialNumber]);

  const handleZeroButtonUp = useCallback(() => {
    // Clear the timer if button is released
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
    // If no plus sign was added (short press), add a zero
    if (!dialNumber.endsWith("+")) {
      handleKeyPress("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longPressTimer, dialNumber]);

  return (
    <div className="dial-pad my-4">
      <div className="field">
        <div className="control">
          <input
            className="input is-large has-text-centered"
            type="tel"
            value={dialNumber}
            onPaste={(e) => {
              e.preventDefault();
              const value = e.clipboardData
                .getData("text")
                ?.replaceAll(" ", "");
              // Only allow numbers, +, *, and #
              setDialNumber(value);
            }}
            onChange={(e) => {
              const value = e.target.value;
              // Only allow numbers, +, *, and #
              if (/^[0-9+*#]*$/.test(value) && value.length <= 15) {
                // Ensure + only appears at the start
                if (value.includes("+") && value.indexOf("+") !== 0) {
                  return;
                }
                setDialNumber(value);
              }
            }}
            placeholder="Enter number"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onCall();
              }
            }}
          />
        </div>
      </div>

      <div className="is-flex is-flex-direction-column is-align-items-center">
        <div className="dial-row my-2">
          {["1", "2", "3"].map((num) => (
            <button
              key={num}
              className="button is-light mx-2 is-size-4"
              style={{ width: "64px", height: "64px" }}
              onClick={() => handleKeyPress(num)}
            >
              {num}
            </button>
          ))}
        </div>
        <div className="dial-row my-2">
          {["4", "5", "6"].map((num) => (
            <button
              key={num}
              className="button is-light mx-2 is-size-4"
              style={{ width: "64px", height: "64px" }}
              onClick={() => handleKeyPress(num)}
            >
              {num}
            </button>
          ))}
        </div>
        <div className="dial-row my-2">
          {["7", "8", "9"].map((num) => (
            <button
              key={num}
              className="button is-light mx-2 is-size-4"
              style={{ width: "64px", height: "64px" }}
              onClick={() => handleKeyPress(num)}
            >
              {num}
            </button>
          ))}
        </div>
        <div className="dial-row my-2">
          <button
            className="button is-light mx-2 is-size-4"
            style={{ width: "64px", height: "64px" }}
            onClick={() => handleKeyPress("*")}
          >
            *
          </button>
          <button
            className="button is-light mx-2 is-size-4"
            style={{ width: "64px", height: "64px" }}
            onMouseDown={handleZeroButtonDown}
            onMouseUp={handleZeroButtonUp}
            onMouseLeave={() => {
              if (longPressTimer) {
                clearTimeout(longPressTimer);
                setLongPressTimer(null);
              }
            }}
            onTouchStart={handleZeroButtonDown}
            onTouchEnd={handleZeroButtonUp}
          >
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <span>0</span>
              <span className="is-size-7">+</span>
            </div>
          </button>
          <button
            className="button is-light mx-2 is-size-4"
            style={{ width: "64px", height: "64px" }}
            onClick={() => handleKeyPress("#")}
          >
            #
          </button>
        </div>
      </div>

      <div className="is-flex is-justify-content-center mt-4">
        <button
          className="button is-danger mx-2"
          onClick={handleBackspace}
          disabled={dialNumber.length === 0}
        >
          <FaBackspace size={20} />
        </button>
        <button
          className="button is-success mx-2 is-flex is-align-items-center"
          onClick={onCall}
          disabled={dialNumber.length === 0 || loading}
        >
          <FaPhoneAlt size={20} className="mr-2" />
          <span>Call</span>
        </button>
      </div>
    </div>
  );
};

const InProgressCall = ({
  callerId,
  onHangUp,
  seconds,
}: {
  callerId?: string;
  onHangUp: () => void;
  seconds: number;
}) => {
  const { voiceCallMeta } = useStore("bots");
  const [dialNumber, setDialNumber] = useState("");

  const handleKeyPress = (key: string) => {
    try {
      voiceCallMeta?.conn && voiceCallMeta.conn.sendDigits(key);
    } catch (error) {
      console.log("error", error);
    }

    if (dialNumber.length < 15) {
      setDialNumber((prev) => prev + key);
    }
  };

  const handleBackspace = () => {
    setDialNumber((prev) => prev.slice(0, -1));
  };

  return (
    <div className="has-text-centered">
      <div className="is-flex is-justify-content-center mb-4">
        <div
          className="is-flex is-justify-content-center is-align-items-center has-background-success-light"
          style={{ width: "64px", height: "64px", borderRadius: "50%" }}
        >
          <FaPhoneAlt className="has-text-success" size={32} />
        </div>
      </div>
      <p className="is-size-5 mb-2">
        <strong>{callerId}</strong>
      </p>
      <p className="is-size-4 has-text-weight-bold mb-5">
        {Duration.fromObject({ second: seconds }).toFormat("mm:ss")}
      </p>
      <div className="dial-pad my-4">
        <div className="field">
          <div className="control">
            <input
              className="input is-large has-text-centered"
              type="text"
              value={dialNumber}
              readOnly
              placeholder="Enter number"
            />
          </div>
        </div>

        <div className="is-flex is-flex-direction-column is-align-items-center">
          <div className="dial-row my-2">
            {["1", "2", "3"].map((num) => (
              <button
                key={num}
                className="button is-light mx-2 is-size-4"
                style={{ width: "64px", height: "64px" }}
                onClick={() => handleKeyPress(num)}
              >
                {num}
              </button>
            ))}
          </div>
          <div className="dial-row my-2">
            {["4", "5", "6"].map((num) => (
              <button
                key={num}
                className="button is-light mx-2 is-size-4"
                style={{ width: "64px", height: "64px" }}
                onClick={() => handleKeyPress(num)}
              >
                {num}
              </button>
            ))}
          </div>
          <div className="dial-row my-2">
            {["7", "8", "9"].map((num) => (
              <button
                key={num}
                className="button is-light mx-2 is-size-4"
                style={{ width: "64px", height: "64px" }}
                onClick={() => handleKeyPress(num)}
              >
                {num}
              </button>
            ))}
          </div>
          <div className="dial-row my-2">
            {["*", "0", "#"].map((num) => (
              <button
                key={num}
                className="button is-light mx-2 is-size-4"
                style={{ width: "64px", height: "64px" }}
                onClick={() => handleKeyPress(num)}
              >
                {num}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="is-flex is-justify-content-center mt-4">
        <button
          className="button has-background-grey-lighter mx-2"
          onClick={handleBackspace}
          disabled={dialNumber.length === 0}
        >
          <FaBackspace size={20} />
        </button>
        <button
          className="button is-danger is-flex is-align-items-center"
          onClick={onHangUp}
        >
          <FaPhoneAlt size={20} className="mr-2" />
          <span>Hang Up</span>
        </button>
      </div>
    </div>
  );
};
