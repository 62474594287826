import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Modal, SingleSelect } from "../../../components";
import { useStore } from "../../../stores";
import locale from "../../../constants/locale";
import { toast } from "bulma-toast";
import * as Yup from "yup";

type Props = {
  messageId: number;
  isOpen: boolean;
  onClose: () => void;
};

export const PinMessageModal = ({ messageId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { selectedWorkspace } = useStore("workspaces");
  const { pinMessage } = useStore("chats");

  if (!selectedWorkspace) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        duration: undefined,
      }}
      validationSchema={Yup.object().shape({
        duration: Yup.number().required(),
      })}
      onSubmit={async (values: any) => {
        try {
          await pinMessage(messageId, values.duration);
          toast({
            message: t(locale.messagePinnedSuccess),
            position: "top-right",
            dismissible: true,
            type: "is-success",
            pauseOnHover: true,
          });
          onClose();
        } catch (e) {
          console.log("error", e);
          toast({
            message: t(locale.genericError),
            position: "top-right",
            dismissible: true,
            type: "is-danger",
            pauseOnHover: true,
          });
        }
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Modal
          isOpen={isOpen}
          title={t(locale.chooseHowLongYourPinLasts)}
          onClose={() => {
            resetForm();
            onClose();
          }}
          primaryLoading={isSubmitting}
          primaryText={t(locale.pin)}
          onPrimary={submitForm}
          allowCardOverflow
        >
          <SingleSelect
            name="duration"
            label={t(locale.youCanUnpinAtAnyTime)}
            disabled={isSubmitting}
            options={[1, 7, 30]}
            getValue={(v) => v}
            getLabel={(v) =>
              v === 1
                ? t(locale.hours, { value: 24 })
                : t(locale.daysVar, { value: v })
            }
          />
        </Modal>
      )}
    </Formik>
  );
};
