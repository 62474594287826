import { toast } from "bulma-toast";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import Conversation from "../../components/ednaConversations/Conversation";
import Header from "../../components/header/Header";
import { Loader } from "../../components/loader/Loader";
import locale from "../../constants/locale";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import { EdnaChatType } from "../../models/EdnaChat";
import { useStore } from "../../stores";
import styles from "./Teammates.module.scss";
import Chat from "./components/Chat";
import { Collapse } from "../../components";
import Mentions from "./components/mentions/Mentions";
import { LuAtSign } from "react-icons/lu";
import routes from "../../constants/routes";
import { FaCaretRight } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import Threads from "./components/threads/Threads";
import { PiChatCircleDots } from "react-icons/pi";
import { startTour } from "../../utils/tour";
import useTeammates, { ChatInterface } from "../../hooks/useTeammates";

const TeammatesWrapper = () => {
  const { t } = useTranslation();
  const isMobile = useMobileResponsive();
  const { selectedWorkspace, workspaceLoaded } = useStore("workspaces");
  const { newMessageAlerts } = useStore("ednaChats");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { loadUsers } = useStore("users");

  const { userId } = useParams<{
    userId?: string;
  }>();

  useEffect(() => {
    if (!workspaceLoaded || !selectedWorkspace?.id) {
      return;
    }
    const load = async () => {
      setLoading(true);
      await loadUsers(selectedWorkspace.id);
      startTour("dms");
      setLoading(false);
    };
    load();
  }, [workspaceLoaded, selectedWorkspace, loadUsers]);

  const { chats } = useTeammates();

  return (
    <div className="w-100-responsive">
      {!isMobile && <Header isMobile={isMobile} title={t(locale.teammates)} />}

      <div className={clsx(styles.h90, "is-flex is-flex-grow-1")}>
        <div
          className={clsx(
            "all-dms full-screen-mobile is-overflow-y-auto py-3 px-4 is-flex-shrink-0 has-border-right",
            styles.container,
            {
              "is-active": !userId,
            }
          )}
        >
          <div
            onClick={() =>
              history.push(
                routes.teammates
                  .replace(":workspaceId", String(selectedWorkspace?.id))
                  .replace(":userId", "mentions")
              )
            }
            className={clsx(
              "has-text-black has-text-weight-semibold is-relative is-flex w-100 px-3 py-2 is-list-item is-clickable is-align-items-center",
              {
                "is-active": userId === "mentions",
              }
            )}
          >
            <LuAtSign />
            <span className="ml-1">{t(locale.mentions)}</span>
          </div>
          <div
            onClick={() =>
              history.push(
                routes.teammates
                  .replace(":workspaceId", String(selectedWorkspace?.id))
                  .replace(":userId", "threads")
              )
            }
            className={clsx(
              "has-text-black has-text-weight-semibold is-relative is-flex w-100 px-3 py-2 is-list-item is-clickable is-align-items-center",
              {
                "is-active": userId === "threads",
              }
            )}
          >
            <PiChatCircleDots />
            <span className="ml-1">{t(locale.threads)}</span>
          </div>
          <CollapseWrapper name={locale.aITeammates}>
            {chats
              .filter((f) =>
                [EdnaChatType.BOT, EdnaChatType.AI_TEAM].includes(f.type)
              )
              ?.map((chat) => (
                <Chat
                  chat={chat}
                  newMessage={newMessageAlerts?.indexOf(chat.id || "") !== -1}
                />
              ))}
          </CollapseWrapper>

          <CollapseWrapper name={locale.humanTeammates}>
            {chats
              .filter((f) => f.type === EdnaChatType.SINGLE)
              ?.map((chat) => (
                <Chat
                  chat={chat}
                  newMessage={newMessageAlerts?.indexOf(chat.id || "") !== -1}
                />
              ))}
          </CollapseWrapper>

          <CollapseWrapper
            name={locale.teams}
            key={chats.filter((f) => f.type === EdnaChatType.CHANNEL).length}
          >
            {chats
              .filter((f) => f.type === EdnaChatType.CHANNEL)
              ?.map((chat) => (
                <Chat
                  chat={chat}
                  newMessage={newMessageAlerts?.indexOf(chat.id || "") !== -1}
                />
              ))}
          </CollapseWrapper>
        </div>
        <div className="is-relative w-100 is-flex">
          {!loading && userId && !["mentions", "threads"].includes(userId) && (
            <Teamates chats={chats} />
          )}
          {!loading && userId === "mentions" && <Mentions chats={chats} />}
          {!loading && userId === "threads" && <Threads chats={chats} />}
        </div>
      </div>
    </div>
  );
};

const Teamates = observer(({ chats }: { chats: ChatInterface[] }) => {
  const { findingChat, firstOrCreateChat, chatInfo, setChatInfo } =
    useStore("ednaChats");
  const [loadingChat, setLoadingChat] = useState(false);
  const auth = useStore("auth");

  const { userId } = useParams<{
    userId?: string;
  }>();

  const getChat = useCallback(async () => {
    if (!userId) return;

    setLoadingChat(true);
    const chat = chats?.find((f) => f.id === userId);

    if (!chat) {
      toast({
        message: "Chat not found",
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
      return;
    }

    try {
      setChatInfo({
        id: chat.id,
        type: chat.type,
        name: chat.name || "",
        profileUrl: chat.image,
        extras: chat.extras,
      });

      let room;

      if (chat.type === EdnaChatType.AI_TEAM) {
        room = `ai-team-${chat.id}`;
      } else if (chat.type === EdnaChatType.CHANNEL) {
        room = `livecard-${chat.id}`;
      } else {
        room = [chat.id, auth.user?.id || ""]
          .sort((a, b) => a.localeCompare(b))
          .join("|");
      }

      await firstOrCreateChat(chat.type, room);
      setLoadingChat(false);
    } catch (error) {
      console.log("error", error);
    }
  }, [userId, chats, auth.user?.id, firstOrCreateChat, setChatInfo]);

  useEffect(() => {
    getChat();
  }, [getChat]);

  return (
    <>
      {loadingChat || findingChat ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <Conversation chatInfo={chatInfo} />
      )}
    </>
  );
});

export const CollapseWrapper = ({
  children,
  name,
}: {
  children: any;
  name: string;
}) => {
  const { t } = useTranslation();

  return (
    <Collapse
      key={name}
      defaultExpanded={true}
      renderTrigger={(trigger) => (
        <div
          {...trigger}
          className={clsx(
            "has-text-weight-semibold is-relative is-flex w-100 px-3 py-2 is-list-item has-text-black is-clickable is-align-items-center"
          )}
        >
          {trigger.isExpanded ? <FaCaretDown /> : <FaCaretRight />}
          <span className="ml-1">{t(name)}</span>
        </div>
      )}
    >
      {children}
    </Collapse>
  );
};

export default observer(TeammatesWrapper);
