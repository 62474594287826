export default function HtmlRender({ html }: { html: string }) {
  return (
    <iframe
      title="Rendered HTML"
      srcDoc={html}
      style={{
        overflow: "hidden",
        width: "500px",
        height: "400px",
        border: "none",
      }}
    />
  );
}
