import { useTranslation } from "react-i18next";
import { useStore } from "../../../../stores";
import locale from "../../../../constants/locale";
import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import styles from "../../components/ChatInfo.module.scss";
import { observer } from "mobx-react-lite";
import * as yup from "yup";
import { Formik } from "formik";
import randomColor from "randomcolor";
import { ColorInput, CreatableSelect, Modal } from "../../../../components";
import { toast } from "bulma-toast";

const Labels = ({
  value,
  onChange,
}: {
  value?: string[];
  onChange?: (value: string[]) => void;
}) => {
  const { t } = useTranslation();
  const { labels, loadLabels } = useStore("bots");
  const [modalOpen, setModalOpen] = useState(false);

  const canLoad = !labels.length;

  useEffect(() => {
    if (!canLoad) {
      return;
    }

    loadLabels();
  }, [canLoad, loadLabels]);

  return (
    <div>
      <span className="label has-text-grey">{t(locale.labels)}</span>
      <button
        className="button is-rounded is-ghosted mb-4 is-small"
        onClick={() => setModalOpen(true)}
        type="button"
      >
        <div className="icon" style={{ position: "absolute" }}>
          <img style={{ width: 12 }} src="/assets/add-blue.svg" alt="" />
        </div>
      </button>

      {value?.map((m) => {
        const label = labels.find((f) => f.id === m) as any;

        if (!label?.color || !label?.name) {
          return null;
        }

        return (
          <div
            key={label.id}
            className={clsx(styles.labelDiv, "columns is-mobile")}
          >
            <div
              className="column is-clickable px-1 py-3"
              style={{
                width: "12%",
                flex: "none",
              }}
            >
              <div
                className={clsx("mr-2")}
                style={{
                  borderRadius: "50%",
                  border: "2px solid " + label.color,
                  height: "1.2rem",
                  width: "1.2rem",
                }}
              />
            </div>
            <div
              className="column is-clickable px-0 py-3 has-long-text"
              style={{
                width: "76%",
                flex: "none",
              }}
            >
              {label.name}
            </div>
            <div
              className="column px-1 py-3"
              style={{
                width: "12%",
                flex: "none",
              }}
            >
              <span
                className="icon is-clickable"
                onClick={() => {
                  onChange && onChange(value.filter((f) => f !== m));
                }}
              >
                <img src="/assets/close.svg" alt="close" />
              </span>
            </div>
          </div>
        );
      })}

      {modalOpen && (
        <CreateLabelModal
          open={true}
          onClose={() => setModalOpen(false)}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default observer(Labels);

const CreateLabelModal = observer(
  ({
    open,
    onClose,
    disabled,
    value,
    onChange,
  }: {
    open: boolean;
    onClose: () => void;
    disabled?: boolean;
    value?: string[];
    onChange?: (value: string[]) => void;
  }) => {
    const { t } = useTranslation();
    const { labels, addLabel } = useStore("bots");

    const availableLabels = useMemo(
      () =>
        labels
          .filter((f) => !value?.includes(f.id))
          .map((m) => ({ label: m.name, value: m.id, color: m.color })),
      [labels, value]
    );

    const schema = yup.object().shape({
      name: yup.mixed().required(t(locale.fieldRequired)),
    });

    return (
      <Formik
        initialValues={{
          name: undefined as { value: string; label: string } | undefined,
          color: randomColor(),
          deadline: undefined,
        }}
        validationSchema={schema}
        onSubmit={async ({ name, color }, actions) => {
          try {
            const label = await addLabel(name?.label.trim() || "", color);

            if (!label) {
              return;
            }

            onChange && onChange([...(value ?? []), label]);

            actions.resetForm();
            onClose();
          } catch (error: any) {
            if (error.message === "labels-creation/restricted") {
              toast({
                message: t(locale.restrictedLabelCreation),
                type: "is-danger",
                dismissible: true,
                pauseOnHover: true,
              });
            }
          }
        }}
      >
        {({ isSubmitting, setFieldValue, submitForm, values }) => (
          <Modal
            isOpen={open}
            onClose={onClose}
            title={t(locale.addLabel)}
            primaryText={t(locale.add)}
            disablePrimary={isSubmitting || disabled}
            onPrimary={() => submitForm()}
            allowCardOverflow
          >
            <CreatableSelect
              label={t(locale.label)}
              name="name"
              className={clsx(styles.labelCreateable)}
              icon={() => (
                <div style={{ marginTop: "-0.8rem" }}>
                  <ColorInput
                    name="color"
                    disabled={
                      isSubmitting ||
                      labels.some((s) => s.id === values.name?.value)
                    }
                  />
                </div>
              )}
              options={availableLabels}
              placeholder={t(locale.addLabel)}
              onChange={(v: any) => {
                if (v.color) {
                  setFieldValue("color", v.color);
                }
              }}
              disabled={isSubmitting || disabled}
              formatOptionLabel={({ color, label }, meta) => (
                <div className="is-flex is-align-items-center">
                  {meta.context === "menu" && (
                    <div
                      className={clsx("mr-2")}
                      style={{
                        borderRadius: "50%",
                        border: "2px solid " + color,
                        height: "1.2rem",
                        width: "1.2rem",
                      }}
                    />
                  )}
                  <span className="is-flex-grow-1">{label}</span>
                </div>
              )}
            />
          </Modal>
        )}
      </Formik>
    );
  }
);
