import { useCallback, useState } from "react";
import { EdnaChat } from "../../../models";
import { CiEdit } from "react-icons/ci";
import AvatarModal from "../../../pages/auth/AvatarModal";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../stores";

export default function UpdateProfile({ chat }: { chat?: EdnaChat }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { selectedWorkspace, updateWorkspace } = useStore("workspaces");

  const onUpload = useCallback(
    async (file: string) => {
      const liveCardId = parseInt(chat?.room.replace("livecard-", "") || "");
      await updateWorkspace({
        ...selectedWorkspace,
        liveCardProfiles: {
          ...selectedWorkspace?.liveCardProfiles,
          [liveCardId]: file,
        },
      });
    },
    [chat?.room, selectedWorkspace, updateWorkspace]
  );

  return (
    <>
      <span
        className="is-clickable"
        onClick={() => setOpen(true)}
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
        }}
      >
        <CiEdit size={16} />
      </span>
      <AvatarModal
        title={t(locale.editProfile)}
        open={open}
        onClose={() => setOpen(false)}
        onChange={onUpload}
        successMessage="Profile updated successfully"
      />
    </>
  );
}
