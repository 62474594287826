import { HTMLProps } from "react";
import { TFunctionResult } from "i18next";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";
import PhoneInput from "react-phone-number-input";

type Props = {
  label?: React.ReactChild | TFunctionResult;
  icon?: string | (() => React.ReactChild);
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  tooltip?: string;
  className?: string;
  isHorizontal?: boolean;
  international?: boolean;
  inputProps?: HTMLProps<HTMLInputElement> | HTMLProps<HTMLTextAreaElement>;
} & FieldConfig<string>;

export const PhoneField = ({ children, ...props }: Props) => {
  const [field, meta, { setValue }] = useField(props);

  const label = typeof props.label === "function" ? props.label() : props.label;

  const inputProps = {
    ...props.inputProps,
    ...field,
    onChange: setValue,
    className: clsx("input", props.className, {
      "is-danger": meta.touched && meta.error,
    }),
    type: props.type,
    placeholder: props.placeholder,
    disabled: props.disabled,
    readOnly: props.readOnly,
    tooltip: props.tooltip,
  };

  const labelHtml = !!label && (
    <label className="label">
      {label}
      {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
    </label>
  );

  const input = (
    <>
      <div
        className={clsx("control", {
          "has-icons-left": !!props.icon,
        })}
      >
        <PhoneInput
          ref={props.innerRef}
          international={props.international}
          {...(inputProps as any)}
        />

        {!!props.icon && (
          <span
            className="icon is-small is-left"
            style={{
              pointerEvents: typeof props.icon === "string" ? "none" : "all",
            }}
          >
            {typeof props.icon === "string" ? (
              <i className={clsx("fas", props.icon)}></i>
            ) : (
              props.icon()
            )}
          </span>
        )}
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </>
  );

  return (
    <div className={clsx("field", { "is-horizontal": props.isHorizontal })}>
      {!props.isHorizontal ? (
        <>
          {labelHtml}
          {input}
        </>
      ) : (
        <>
          <div className="field-label is-normal">{labelHtml}</div>
          <div className="field-body">
            <div className="field">{input}</div>
          </div>
        </>
      )}
    </div>
  );
};
