import { FC, ReactNode } from "react";
import clsx from "clsx";
import styles from "./Header.module.scss";
import ProfilePopover from "../navBar/components/ProfilePopover";

type Props = {
  isMobile: boolean;
  children?: ReactNode;
  title?: ReactNode;
  hideTitleOnMobile?: boolean;
  noTitle?: boolean;
  noProfile?: boolean;
};

const Header: FC<Props> = ({
  children,
  title,
  hideTitleOnMobile,
  isMobile = false,
  noTitle = false,
  noProfile = false,
}: Props) => {
  return (
    <div
      className={clsx(
        styles.header,
        ["px-" + (isMobile ? "3" : "6")],
        "has-border-bottom is-align-items-center is-flex is-justify-content-space-between is-justify-content-stretch "
      )}
    >
      {!!title && (
        <h4
          className={clsx(styles.title, {
            "sm-hidden": hideTitleOnMobile,
            "has-text-primary": isMobile,
          })}
        >
          {title}
        </h4>
      )}
      {noTitle && <span></span>}
      <div className="is-flex" style={{ gap: "1rem" }}>
        {children}
        {!isMobile && !noProfile && <ProfilePopover />}
      </div>
    </div>
  );
};

export default Header;
