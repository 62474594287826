/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Formik } from "formik";
import { toast } from "bulma-toast";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../stores";
import {
  DatePicker,
  Field,
  Modal,
  MultiSelect,
  Select,
} from "../../../../components";
import firebase from "../../../../services/firebase";
import locale from "../../../../constants/locale";
import firebaseApp from "firebase/compat/app";
import { useHistory, useLocation, useParams } from "react-router";
import {
  Ticket,
  TicketPriority,
  ticketPriorityMap,
  ticketRepeatDailyOptionMap,
  TicketRepeatFrequency,
  ticketRepeatMap,
  ticketRepeatMonthlyOptionMap,
  ticketRepeatYearlyOptionMap,
  TicketStatus,
  ticketStatusMap,
  TicketType,
  ticketTypeMap,
} from "../../../../models";
import * as yup from "yup";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { notifyUserForNewTask } from "../../../../services/chathub";
import UploadAttachment from "./UploadAttachment";
import { AntSelect } from "../../../../components/select/AntSelect";
import Labels from "./Labels";
import clsx from "clsx";
import { NotificationType } from "../../../../models/Notification";
import Comments from "../../../../components/comments/Comments";
import MetaData from "./MetaData";
import Histories from "./Histories";
import { AccountSelector } from "../../../board/components/ItemModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUpdate?: () => void;
  ticket?: Ticket;
  mode: "add" | "edit";
  isHelpCenter?: boolean;
};

const TicketModal = ({
  isOpen,
  onClose,
  onUpdate,
  ticket,
  mode,
  isHelpCenter,
}: Props) => {
  const { t } = useTranslation();
  const { selectedBot, activeLiveChatCards } = useStore("bots");
  const { triggerWorkflowEvent } = useStore("workflows");
  const { createNotification } = useStore("notifications");
  const { addChatLog } = useStore("chats");
  const { users } = useStore("users");
  const { user } = useStore("auth");
  const [tab, setTab] = useState("comments");
  const [tempFiles, setTempFiles] = useState<string[]>([]);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const paramCommentId = queryParams.get("commentId");

  const { group, chatId } = useParams<{
    group: string;
    chatId?: string;
  }>();

  const schema = yup.object().shape({
    name: yup.string().required(t(locale.fieldRequired)),
    description: yup.string().max(20000).required(t(locale.fieldRequired)),
    assigneeId: yup.string().required(t(locale.fieldRequired)),
    status: yup.string().required(t(locale.fieldRequired)),
    dueDate: isHelpCenter
      ? yup.string().optional()
      : yup.string().required(t(locale.fieldRequired)),
    liveCardId: yup.number().required(t(locale.fieldRequired)),
    chatId: yup.number().optional(),
    priority: yup.string().required(t(locale.fieldRequired)),
  });

  const notifyMentionedUsers = useCallback(
    async (ticket: Ticket) => {
      if (!selectedBot || !ticket?.comments?.length) {
        return;
      }
      const filter = ticket?.comments?.filter((f) => !f.notified);

      for (const comment of filter) {
        const userIds =
          comment.comment
            ?.match(/(@\[.*?\]\([^)]+\))/g)
            ?.map((f) => f.replace(/(@\[.*?\]\(|\))/g, "")) || [];

        const uniqueUserIds = Array.from(new Set(userIds));
        for (const userId of uniqueUserIds) {
          await createNotification(userId, NotificationType.TICKET_MENTION, {
            ticket,
            comment,
          });
        }
      }

      const comments = ticket.comments.map((f) => {
        f.notified = true;
        return f;
      });

      await firebase
        .firestore()
        .collection("bots")
        .doc(String(selectedBot.id))
        .collection("tasks")
        .doc(ticket.id)
        .update({ comments });
    },
    [createNotification, selectedBot]
  );

  useEffect(() => {
    if (isOpen && paramCommentId && ticket?.comments?.length) {
      const comment = ticket.comments.find((f) => f.id === paramCommentId);
      if (comment) {
        setTimeout(() => {
          document
            .getElementById("ticket-comment-" + comment.id)
            ?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          const { pathname } = location;
          history.push(pathname);
        }, 200);
      }
    }
  }, [
    history,
    isOpen,
    location,
    paramCommentId,
    ticket?.comments,
    ticket?.comments?.length,
  ]);

  if (!selectedBot || !user) {
    return null;
  }

  const beforeOnClose = async () => {
    if (!tempFiles.length) {
      return;
    }
    try {
      for (let file of tempFiles) {
        const ref = firebase.storage().ref(`files`);

        const fileRef = ref.child(file);
        await fileRef.delete();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitTicket = async (data: any) => {
    try {
      if (mode === "edit" && ticket?.id) {
        const oldHistories = ticket.histories || [];
        if (oldHistories?.length) {
          const index = oldHistories.length - 1;
          oldHistories[index].removedAt = new Date();
          oldHistories[index].newStatus = data.status;
        }
        data.histories = [
          ...oldHistories,
          {
            createdBy: user.id,
            currentStatus: data.status,
            addedAt: new Date(),
          },
        ];
        await firebase
          .firestore()
          .collection("bots")
          .doc(String(selectedBot.id))
          .collection("tasks")
          .doc(ticket.id)
          .set({
            ...data,
            updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
          });
      } else {
        data.histories = [
          {
            createdBy: user.id,
            currentStatus: data.status,
            addedAt: new Date(),
          },
        ];
        const ref = await firebase
          .firestore()
          .collection("bots")
          .doc(String(selectedBot.id))
          .collection("tasks")
          .add({
            userId: user.id,
            ...data,
            createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
          });

        data.id = ref.id;

        await addChatLog(data.chatId, {
          type: "task_created",
          data: { task: [data] },
        });
      }

      if (data.status !== ticket?.status) {
        triggerWorkflowEvent("task_properties", {
          chatId: data.chatId,
          taskStatus: data.status,
        });
      }

      if (data.liveCardId !== ticket?.liveCardId) {
        triggerWorkflowEvent("task_properties", {
          chatId: data.chatId,
          liveCardId: data.liveCardId,
        });
      }

      if (ticket?.assigneeId !== data.assigneeId) {
        const user = users?.find((f) => f.id === data.assigneeId);
        if (user && user.email) {
          await notifyUserForNewTask(
            selectedBot.id,
            selectedBot.key,
            selectedBot.password,
            user.email,
            group ?? data.liveCardId
          );
        }
      }

      await notifyMentionedUsers(data);

      onClose();
      onUpdate && onUpdate();
    } catch (error) {
      if (error instanceof Error) {
        toast({
          message: error.message,
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
      console.log(error);
    }
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          name: undefined,
          description: undefined,
          assigneeId: undefined,
          status: TicketStatus.TODO,
          priority: TicketPriority.MEDIUM,
          liveCardId: group ? parseInt(group) : undefined,
          chatId: chatId ? parseInt(chatId) : undefined,
          ...ticket,
          type: ticket?.type ?? TicketType.TASK,
          labels: ticket?.labels ?? [],
          frequencyConfig: ticket?.frequencyConfig ?? [],
          dueDate: ticket?.dueDate
            ? ticket.dueDate instanceof Date
              ? ticket.dueDate
              : ticket.dueDate?.toDate()
            : undefined,
          attachments: ticket?.attachments ?? [],
        }}
        onSubmit={submitTicket}
      >
        {({ isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
          <Modal
            isOpen={isOpen}
            title={t(mode + "Ticket")}
            onClose={async () => {
              resetForm();
              await beforeOnClose();
              onClose();
            }}
            isLargeScreen
            primaryText={t(locale.save)}
            primaryLoading={isSubmitting}
            onPrimary={isSubmitting ? undefined : submitForm}
          >
            <Form className="columns is-multiline">
              <div className="column is-half">
                <Field
                  label={t(locale.name)}
                  name="name"
                  className="custom-input"
                  disabled={isSubmitting}
                />
                <AntSelect
                  showSearch
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  name="assigneeId"
                  label={t(locale.assignee)}
                  allowClear
                  options={
                    users?.map((f) => ({
                      value: f.id,
                      label: f.name || f.email,
                    })) || []
                  }
                />
              </div>
              <div className="column is-half">
                <Field
                  label={t(locale.description)}
                  name="description"
                  useTextArea
                  className="custom-input"
                  inputProps={{
                    maxLength: 1024,
                    style: { height: 135, resize: "none" },
                  }}
                  disabled={isSubmitting}
                />
              </div>
              {!isHelpCenter && (
                <div className="column is-half">
                  <DatePicker
                    name="dueDate"
                    label={t(locale.dueDate)}
                    disabled={isSubmitting}
                    inputClass="custom-input"
                    wrapperClassName="w-100"
                    showTimeInput
                    dateFormat="MM/dd/yyyy h:mm aa"
                  />
                </div>
              )}
              <div className="column is-half">
                <Select
                  name="priority"
                  label={t(locale.priority)}
                  disabled={isSubmitting}
                  options={Object.keys(ticketPriorityMap)}
                  getLabel={(l) => t(ticketPriorityMap[l as TicketPriority])}
                  className="custom-select"
                  labelIsNotAOption
                />
              </div>
              <div className="column is-half">
                <Select
                  name="status"
                  label={t(locale.status)}
                  disabled={isSubmitting}
                  options={[
                    TicketStatus.TODO,
                    TicketStatus.IN_PROGRESS,
                    TicketStatus.DONE,
                    TicketStatus.CLOSED,
                  ]}
                  getLabel={(l) => t(ticketStatusMap[l])}
                  className="custom-select"
                  labelIsNotAOption
                />
              </div>
              <div className="column is-half">
                <Select
                  name="type"
                  label={t(locale.type)}
                  disabled={isSubmitting}
                  options={[TicketType.TASK, TicketType.TICKET]}
                  getLabel={(l) => t(ticketTypeMap[l])}
                  className="custom-select"
                  labelIsNotAOption
                />
              </div>
              <div className="column is-half">
                <AccountSelector
                  disabled={isSubmitting}
                  name={"chatId"}
                  subcriber={
                    ticket?.chatId &&
                    ({
                      id: ticket?.chatId,
                    } as any)
                  }
                />
              </div>
              {!isHelpCenter && (
                <div className="column is-half">
                  <Select
                    name="liveCardId"
                    label={"Group"}
                    disabled={isSubmitting}
                    options={activeLiveChatCards}
                    getLabel={(l) => l.name}
                    getValue={(l) => l.id}
                    className="custom-select"
                  />
                </div>
              )}
              {!isHelpCenter && (
                <div className="column is-half">
                  <Select
                    name="repeatFrequency"
                    label={t(locale.repeat)}
                    disabled={isSubmitting}
                    options={Object.keys(ticketRepeatMap)}
                    getLabel={(l) => t(ticketRepeatMap[l as never])}
                    onChange={() => setFieldValue("frequencyConfig", [])}
                    className="custom-select"
                    labelIsNotAOption
                    extras={
                      values.repeatFrequency &&
                      values.repeatFrequency !== TicketRepeatFrequency.NONE ? (
                        <span className="subtitle is-7 has-text-grey">
                          {"Cron job will be run on utc time"}
                        </span>
                      ) : (
                        <span></span>
                      )
                    }
                  />
                  {values.repeatFrequency === TicketRepeatFrequency.WEEKLY && (
                    <MultiSelect
                      name="frequencyConfig"
                      label={t(locale.every)}
                      options={Object.keys(ticketRepeatDailyOptionMap).map(
                        (f) => parseInt(f)
                      )}
                      getLabel={(v) =>
                        t(ticketRepeatDailyOptionMap[v as never])
                      }
                    />
                  )}
                  {values.repeatFrequency === TicketRepeatFrequency.MONTHLY && (
                    <MultiSelect
                      name="frequencyConfig"
                      label={t(locale.every)}
                      options={Object.keys(ticketRepeatMonthlyOptionMap).map(
                        (f) => parseInt(f)
                      )}
                      getLabel={(v) =>
                        t(ticketRepeatMonthlyOptionMap[v as never])
                      }
                    />
                  )}
                  {values.repeatFrequency === TicketRepeatFrequency.YEARLY && (
                    <MultiSelect
                      name="frequencyConfig"
                      label={t(locale.every)}
                      options={Object.keys(ticketRepeatYearlyOptionMap).map(
                        (f) => parseInt(f)
                      )}
                      getLabel={(v) =>
                        t(ticketRepeatYearlyOptionMap[v as never])
                      }
                    />
                  )}
                </div>
              )}

              <div className="column is-full" style={{ minHeight: "16rem" }}>
                <div className="tabs">
                  <ul>
                    <li className={clsx({ "is-active": tab === "comments" })}>
                      <a onClick={() => setTab("comments")}>
                        {t(locale.comments)}
                      </a>
                    </li>
                    <li
                      className={clsx({ "is-active": tab === "attachments" })}
                    >
                      <a onClick={() => setTab("attachments")}>
                        {t(locale.attachments)}
                      </a>
                    </li>
                    <li className={clsx({ "is-active": tab === "labels" })}>
                      <a onClick={() => setTab("labels")}>{t(locale.labels)}</a>
                    </li>
                    <li className={clsx({ "is-active": tab === "history" })}>
                      <a onClick={() => setTab("history")}>
                        {t(locale.history)}
                      </a>
                    </li>
                    <li className={clsx({ "is-active": tab === "meta-data" })}>
                      <a onClick={() => setTab("meta-data")}>
                        {t(locale.metaData)}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="columns m-0">
                  <div className="column is-half">
                    {tab === "comments" && (
                      <Comments
                        value={values?.comments}
                        onChange={(v) => setFieldValue("comments", v)}
                      />
                    )}
                    {tab === "labels" && (
                      <Labels
                        value={values?.labels}
                        onChange={(v) => setFieldValue("labels", v)}
                      />
                    )}
                    {tab === "history" && <Histories ticket={ticket} />}
                    {tab === "attachments" && (
                      <UploadAttachment
                        value={values?.attachments}
                        onChange={(v) => setFieldValue("attachments", v)}
                        setTempFiles={setTempFiles}
                      />
                    )}
                    {tab === "meta-data" && <MetaData meta={values?.meta} />}
                  </div>
                </div>
              </div>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default observer(TicketModal);
