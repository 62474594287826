import { observer } from "mobx-react-lite";
import { CustomField } from "../../../models";
import { useTranslation } from "react-i18next";
import locale from "../../../constants/locale";
import { FieldArray, Formik } from "formik";
import { AntSelect, DatePicker, Field, Modal } from "../../../components";
import { useState } from "react";
import * as yup from "yup";
import clsx from "clsx";

const CustomFields = ({
  value,
  onChange,
}: {
  value?: CustomField[];
  onChange?: (v: CustomField[]) => void;
}) => {
  const { t } = useTranslation();
  const [settingsModal, setSettingsModal] = useState(false);

  return (
    <div>
      <div
        className="is-flex is-justify-content-end mb-4"
        style={{ width: "40%" }}
      >
        <button
          type="button"
          className="button is-primary is-small"
          onClick={() => setSettingsModal(true)}
        >
          {t(locale.manage)}
        </button>
      </div>

      <FieldArray
        name="customFields"
        render={(arrayHelpers) => (
          <div>
            {value?.map((field: any, index: number) => (
              <div key={index} className="columns">
                <div className="column is-5">
                  {field.type === "date" ? (
                    <DatePicker
                      label={field.name}
                      placeholder={field.name}
                      name={`customFields.${index}.value`}
                      wrapperClassName="w-100"
                    />
                  ) : (
                    <Field
                      label={field.name}
                      placeholder={field.name}
                      name={`customFields.${index}.value`}
                      type={field.type}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      />

      {settingsModal && (
        <ManageFieldModal
          customFields={value}
          onUpdate={(v) => {
            onChange && onChange(v ?? []);
          }}
          onClose={() => setSettingsModal(false)}
        />
      )}
    </div>
  );
};

export const ManageFieldModal = ({
  onClose,
  customFields,
  onUpdate,
  inlineValue,
}: {
  customFields?: CustomField[];
  onUpdate: (fields?: CustomField[]) => void;
  onClose: () => void;
  inlineValue?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        customFields,
      }}
      validationSchema={yup.object({
        customFields: yup.array().of(
          yup.object({
            name: yup.string().required(t(locale.requiredField)),
            type: yup.string().required(t(locale.requiredField)),
            value: inlineValue
              ? yup.string().required(t(locale.requiredField))
              : yup.string(),
          })
        ),
      })}
      onSubmit={(values) => {
        onUpdate(values.customFields);
        onClose();
      }}
    >
      {({ isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
        <Modal
          isOpen
          title={t(locale.manageCustomFields)}
          onClose={onClose}
          primaryText={t(locale.update)}
          primaryLoading={isSubmitting}
          onPrimary={isSubmitting ? undefined : submitForm}
          isMediumScreen={inlineValue}
        >
          <FieldArray
            name="customFields"
            render={(arrayHelpers) => (
              <div>
                {values.customFields?.map((field: any, index: number) => (
                  <div key={index} className="columns">
                    <div
                      className={clsx("column", {
                        "is-5": !inlineValue,
                        "is-3": inlineValue,
                      })}
                    >
                      <Field
                        name={`customFields.${index}.name`}
                        label={t(locale.name)}
                      />
                    </div>
                    <div
                      className={clsx("column", {
                        "is-5": !inlineValue,
                        "is-3": inlineValue,
                      })}
                    >
                      <AntSelect
                        name={`customFields.${index}.type`}
                        label={t(locale.type)}
                        allowClear
                        showSearch
                        options={[
                          { label: "Text", value: "text" },
                          { label: "Number", value: "number" },
                          { label: "Date", value: "date" },
                        ]}
                        onChange={() => {
                          setFieldValue(
                            `customFields.${index}.value`,
                            undefined
                          );
                        }}
                      />
                    </div>
                    {inlineValue && (
                      <div
                        className={clsx("column", {
                          "is-5": !inlineValue,
                          "is-3": inlineValue,
                        })}
                      >
                        {field.type === "date" ? (
                          <DatePicker
                            label={t(locale.value)}
                            placeholder={t(locale.value)}
                            name={`customFields.${index}.value`}
                            wrapperClassName="w-100"
                          />
                        ) : (
                          <Field
                            label={t(locale.value)}
                            placeholder={t(locale.value)}
                            name={`customFields.${index}.value`}
                            type={field.type}
                          />
                        )}
                      </div>
                    )}
                    <div className="column is-2">
                      <button
                        onClick={() => arrayHelpers.remove(index)}
                        className="ml-4 mt-5 button is-ghost is-no-box-shadow"
                      >
                        <div className="icon" style={{ position: "absolute" }}>
                          <img src="/assets/dustbin.svg" alt="" />
                        </div>
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  onClick={() =>
                    arrayHelpers.push({
                      name: undefined,
                      value: "",
                      type: "text",
                    })
                  }
                  className="button is-ghost is-no-box-shadow"
                >
                  <div className="icon" style={{ position: "absolute" }}>
                    <img src="/assets/add.svg" alt="" />
                  </div>
                </button>
              </div>
            )}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default observer(CustomFields);
