import { DateTime } from "luxon";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import styles from "../../../../components/ednaConversations/components/Message.module.scss";
import {
  EdnaChat,
  EdnaChatInfo,
  EdnaChatType,
  EdnaMessage,
  ednaBotUser,
} from "../../../../models/EdnaChat";
import { MessageRenders } from "../../../../components/ednaConversations/components/MessageRenders/MessageRenders";
import { useStore } from "../../../../stores";
import { FallbackImage } from "../../../../components";
import { useHistory } from "react-router";
import routes from "../../../../constants/routes";

const Message = ({
  message: { id, userId, message, createdAt },
  chat,
  chatInfo,
}: {
  message: EdnaMessage;
  chat: EdnaChat;
  chatInfo: EdnaChatInfo;
}) => {
  const { selectedWorkspace } = useStore("workspaces");
  const history = useHistory();
  const { selectedBot } = useStore("bots");
  const { user: authUser } = useStore("auth");
  const { users } = useStore("users");
  let time = "--:--";

  try {
    //@ts-ignore
    const date = DateTime.fromSeconds(createdAt.seconds).plus(
      //@ts-ignore
      createdAt.nanoseconds / 1000000000
    );

    time = date.hasSame(DateTime.utc(), "day")
      ? date.toFormat("t")
      : date.toLocaleString({
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "2-digit",
        });
  } catch (error) {
    console.error(error, id, createdAt);
  }

  let user = users?.find((f) => f.id === userId);
  if (userId === ednaBotUser.id) {
    user = ednaBotUser;
  }
  const isFromUser = true;

  const image = (
    <figure className="is-flex-shrink-0">
      <p className="image is-32x32 is-square">
        <FallbackImage
          className="is-rounded"
          src={chatInfo?.profileUrl}
          fallback={"/assets/avatar.svg"}
          alt={chatInfo.name}
          title={chatInfo.name}
        />
      </p>
    </figure>
  );

  const routeToChat = () => {
    let userId;
    const chatType = chat.threadChatType ?? chat.type;

    if (chatType === EdnaChatType.SINGLE) {
      userId = chat.room.split("|").find((f) => f !== authUser?.id);
    } else if (chatType === EdnaChatType.CHANNEL) {
      userId = chat.room.replace("livecard-", "");
    } else if (chatType === EdnaChatType.AI_TEAM) {
      userId = chat.room.replace("ai-team-", "");
    } else if (chatType === EdnaChatType.CHATHUB_CHAT) {
      history.push(
        routes.chats
          .replace(":workspaceId", String(selectedWorkspace?.id))
          .replace(":functionId", "op")
          .replace(":chatId?", chat.room.replace("chathub-chat-", "")) +
          "?notes=open"
      );
      return;
    }

    history.push(
      routes.teammates
        .replace(":workspaceId", String(selectedWorkspace?.id))
        .replace(
          ":userId?",
          String(userId) +
            (chat.threadMessageId ? "?thread=" + chat.threadMessageId : "")
        )
    );
  };

  return (
    <article
      className={clsx("is-flex py-3 is-relative", {
        "is-justify-content-flex-end": !isFromUser,
        "is-justify-content-flex-start": isFromUser,
      })}
    >
      {isFromUser && image}
      <div
        className={clsx(
          {
            "ml-3": isFromUser,
            "mr-3": !isFromUser,
          },
          "is-flex is-flex-direction-column"
        )}
      >
        <div
          className={clsx(
            "is-flex is-flex-direction-column is-align-items-stretch",
            // styles.container,
            {
              "bubble-left": isFromUser,
              "bubble-right": !isFromUser,
            }
          )}
        >
          <small
            onClick={routeToChat}
            className={clsx(
              "is-flex is-clickable is-flex-direction-column is-justify-content-space-between",
              {
                "mb-2":
                  !isFromUser &&
                  (user?.name || (!isFromUser ? selectedBot?.name : "")),
              }
            )}
          >
            <strong className="title is-6 mb-1 has-text-weight-semibold">
              {chatInfo.name}
            </strong>
            <div className="is-flex is-flex-wrap-wrap">
              {chatInfo.type !== "single" && (
                <span
                  style={{ paddingTop: 2 }}
                  className={clsx(
                    styles.font13,
                    "has-text-weight-medium mb-0 subtitle mr-1"
                  )}
                >
                  {user?.name || userId}:
                </span>
              )}
              <span
                className={clsx(
                  styles.font13,
                  "has-long-text has-text-weight-medium"
                )}
              >
                <MessageRenders message={message} />
              </span>
            </div>
          </small>
        </div>
        <div
          className={clsx(styles.timeColor, {
            "has-text-right": !isFromUser,
          })}
        >
          {time}
        </div>
      </div>
      <div></div>
      {!isFromUser && image}
    </article>
  );
};

export default observer(Message);
