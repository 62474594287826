/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, MenuProps } from "antd";
import { IoChevronDown } from "react-icons/io5";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useStore } from "../../../stores";
import { useState } from "react";
import { PinMessageModal } from "./PinMessageModal";
import { observer } from "mobx-react-lite";
import { ForwardModal } from "./ForwardModal";
import { Message, MetaType } from "../../../models";
import { toast } from "bulma-toast";

export const parseMessage = (message: Message) => {
  if (message.metaType === MetaType.Image) {
    const image = message.isFromUser
      ? message.attachments?.[0]?.payload.url
      : message.attachment?.payload.url;
    return {
      type: "file",
      file: {
        name: message.text,
        link: image,
        type: "image/png",
      },
    };
  } else if ([MetaType.Text].includes(message.metaType!)) {
    return {
      type: "text",
      text: message.text,
    };
  }

  return undefined;
};

const checkMessageAllowedToForward = (message: Message) => {
  return !!parseMessage(message);
};

const ActionDropdown = ({
  isFromUser,
  messageId,
}: {
  isFromUser: boolean;
  messageId: number;
}) => {
  const { selectedChat } = useStore("chats");
  const { t } = useTranslation();
  const [pinModal, setPinModal] = useState(false);
  const [forwardModal, setForwardModal] = useState(false);
  const { messages, confidentialMessage } = useStore("chats");

  const message = messages.find((m) => m.id === messageId);

  if (!selectedChat?.id || !message) return null;

  const items: MenuProps["items"] = [
    {
      key: locale.pin,
      label: t(locale.pin),
      onClick: () => {
        setPinModal(true);
      },
    },
    {
      key: locale.forward,
      label: t(locale.forward),
      disabled: !checkMessageAllowedToForward(message),
      onClick: () => {
        setForwardModal(true);
      },
    },
    {
      key: locale.setConfidential,
      label: message.confidential
        ? t(locale.removeConfidential)
        : t(locale.setConfidential),
      disabled: false,
      onClick: async () => {
        try {
          await confidentialMessage(message.id);
        } catch (e: any) {
          toast({
            message: e.message ?? t(locale.genericError),
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
          });
        }
      },
    },
  ];

  if (!items.length) return null;

  return (
    <div
      style={{
        position: "absolute",
        right: "0",
        top: "0",
      }}
      className="p-1"
    >
      <Dropdown menu={{ items }} trigger={["click"]} destroyPopupOnHide>
        <a
          onClick={(e) => e.preventDefault()}
          className={clsx({
            "has-text-black": isFromUser,
            "has-text-white": !isFromUser,
          })}
        >
          <IoChevronDown />
        </a>
      </Dropdown>
      {pinModal && (
        <PinMessageModal
          messageId={messageId}
          isOpen
          onClose={() => setPinModal(false)}
        />
      )}
      {forwardModal && (
        <ForwardModal
          isOpen
          onClose={() => setForwardModal(false)}
          message={parseMessage(message)}
        />
      )}
    </div>
  );
};

export default observer(ActionDropdown);
