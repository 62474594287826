import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../../stores";
import clsx from "clsx";
import styles from "../mentions/Mentions.module.scss";
import useMobileResponsive from "../../../../hooks/useMobileResponsive";
import { useHistory } from "react-router";
import {
  EdnaChat,
  EdnaChatType,
  EdnaMessage,
} from "../../../../models/EdnaChat";
import locale from "../../../../constants/locale";
import { useTranslation } from "react-i18next";
import { FallbackImage } from "../../../../components";
import Message from "../../../../components/ednaConversations/components/Message";
import routes from "../../../../constants/routes";
import { ChatInterface } from "../../../../hooks/useTeammates";

const Threads = ({ chats }: { chats: ChatInterface[] }) => {
  const { t } = useTranslation();
  const { selectedWorkspace, activeLiveChatCards } = useStore("workspaces");
  const { loadChatThreads, chatThreads } = useStore("ednaChats");

  const auth = useStore("auth");
  const isMobile = useMobileResponsive();
  const history = useHistory();

  useEffect(() => {
    const load = async () => {
      await loadChatThreads();
    };

    load();

    const handle = setInterval(load, 15000, false);

    return () => clearInterval(handle);
  }, [loadChatThreads]);

  const allChats = [
    ...chats,
    ...(activeLiveChatCards?.map((c) => {
      return {
        id: String(c.id),
        name: c.name,
        image: undefined,
        type: EdnaChatType.CHANNEL,
      };
    }) || []),
  ];

  const openChat = (chat: EdnaChat) => {
    const chatInfo = allChats.find((chatM) => {
      let room;

      if (chatM.type === EdnaChatType.AI_TEAM) {
        room = `ai-team-${chatM.id}`;
      } else if (chatM.type === EdnaChatType.CHANNEL) {
        room = "livecard-" + chatM.id;
      } else {
        room = [chatM.id, auth.user?.id || ""]
          .sort((a, b) => a.localeCompare(b))
          .join("|");
      }
      return room === chat?.room;
    });

    if (chatInfo?.type === EdnaChatType.CHANNEL) {
      history.push(
        routes.groupChats
          .replace(":workspaceId", String(selectedWorkspace?.id))
          .replace(":functionId", "op")
          .replace(":group", chatInfo.id + "?thread=" + chat.threadMessageId)
      );
    } else if (chatInfo?.type === EdnaChatType.SINGLE) {
      history.push(
        routes.teammates
          .replace(":workspaceId", String(selectedWorkspace?.id))
          .replace(":userId?", chatInfo?.id + "?thread=" + chat.threadMessageId)
      );
    }
  };

  return (
    <section
      id="section-conversation"
      className={clsx(
        "full-screen-mobile is-flex is-flex-grow-1 is-flex-direction-column has-border-right",
        styles.container,
        {
          "pb-3 chat-bg": !isMobile,
          "is-active": true,
        }
      )}
    >
      <div className={clsx("has-background-light has-border", styles.header)}>
        <div className="is-flex is-align-items-center">
          {isMobile && (
            <button
              className="button is-ghost no-focus"
              onClick={() => history.goBack()}
            >
              <span className="icon">
                <img src="/assets/chevron-left.svg" alt="" />
              </span>
            </button>
          )}
          <h4 className="title is-6 my-2">{t(locale.threads)}</h4>
        </div>
      </div>
      <div className="p-2 is-overflow-y-auto">
        <div>
          {chatThreads.map((chatThread, index) => {
            const chatInfo = allChats.find((chat) => {
              let room;

              if (chat.type === EdnaChatType.AI_TEAM) {
                room = `ai-team-${chat.id}`;
              } else if (chat.type === EdnaChatType.CHANNEL) {
                room = "livecard-" + chat.id;
              } else {
                room = [chat.id, auth.user?.id || ""]
                  .sort((a, b) => a.localeCompare(b))
                  .join("|");
              }
              return room === chatThread?.room;
            });
            return (
              <div className="px-2 mb-3" key={index}>
                <div className="is-flex is-align-items-center">
                  <figure
                    className="is-flex-shrink-0 mr-2 is-clickable"
                    onClick={() => openChat(chatThread)}
                  >
                    <p className="image is-40x40 is-square">
                      <FallbackImage
                        className="is-rounded"
                        src={chatInfo?.image}
                        fallback={"/assets/avatar.svg"}
                        alt={chatInfo?.name}
                        title={chatInfo?.name}
                      />
                    </p>
                  </figure>
                  <h2
                    onClick={() => openChat(chatThread)}
                    className="title is-6 has-text-weight-semibold is-clickable"
                  >
                    {chatInfo?.name || chatThread.extras?.name}
                  </h2>
                </div>

                <div className="has-background-light has-border has-rounded-corners p-2 my-2">
                  {chatThread?.messages?.map((m, index) => (
                    <Message
                      key={index}
                      message={m as EdnaMessage}
                      disableThreadReply
                      chatInfo={{
                        id: String(chatThread.id),
                        name: chatInfo?.name || chatThread.extras?.name,
                        profileUrl:
                          chatInfo?.image || chatThread.extras?.profileUrl,
                        type: chatThread.type as never,
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default observer(Threads);
