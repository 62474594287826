import clsx from "clsx";
import styles from "./Conversation.module.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores";
import { Loader } from "../loader/Loader";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import MessageComposer from "./components/MessageComposer";
import Message from "./components/Message";
import { EdnaChatType } from "../../models/EdnaChat";
import locale from "../../constants/locale";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

const ThreadConversation = () => {
  const { t } = useTranslation();
  const { loadMessages, selectedChat, closeThreadChat, findingChat, messages } =
    useStore("ednaThreadChats");
  const [loading, setLoading] = useState(true);
  const [lastMsg, setLastMsg] = useState("");
  const history = useHistory();
  const location = useLocation();
  const divRef = useRef<HTMLDivElement | null>(null);
  const [pageSize, setPageSize] = useState(200);
  const isMobile = useMobileResponsive();

  useEffect(() => {
    if (!selectedChat?.id || findingChat) {
      return;
    }

    setLoading(true);

    const load = async () => {
      await loadMessages(pageSize);
      setLoading(false);
    };

    load();

    const handle = setInterval(load, 15000, false);

    return () => clearInterval(handle);
  }, [loadMessages, pageSize, selectedChat?.id, findingChat]);

  useEffect(() => {
    if (
      loading ||
      !divRef.current ||
      !messages?.length ||
      lastMsg === messages[0]?.id
    ) {
      return;
    }

    setLastMsg(messages[0].id || "");

    divRef.current.scrollTop = divRef.current.scrollHeight + 100;
  }, [loading, lastMsg, messages, messages?.length]);

  return (
    <section
      className={clsx(
        "is-relative is-flex full-screen-mobile is-active",
        styles.threadContainer
      )}
    >
      {findingChat || loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <section
          id="section-thread-conversation"
          className={clsx(
            "full-screen-mobile is-flex is-flex-grow-1 is-flex-direction-column has-border-right",
            styles.container,
            {
              "pb-3": !isMobile,
              "is-active": true,
            }
          )}
        >
          <div
            className={clsx("has-background-light has-border", styles.header)}
          >
            <h4 className="title is-6 mb-0">{t(locale.thread)}</h4>
            <button
              className="button is-ghost no-focus p-0"
              onClick={() => {
                history.push(location.pathname);
                closeThreadChat();
              }}
            >
              <span className="icon">
                <img width={16} src="/assets/close.svg" alt="" />
              </span>
            </button>
          </div>
          <div className="is-flex px-4 is-flex-direction-column is-align-items-stretch is-justify-content-flex-end is-flex-grow-1 is-relative">
            <div
              ref={divRef}
              id="messages_scroll"
              className={clsx(styles.scrollContainer)}
            >
              <InfiniteScroll
                style={{ overflowX: "hidden" }}
                dataLength={messages?.length || 0}
                next={() => setPageSize(pageSize + 50)}
                hasMore={pageSize === messages?.length}
                loader={null}
                scrollableTarget="messages_scroll"
                className="is-flex is-flex-direction-column-reverse"
                inverse
              >
                {messages.map((m) => (
                  <Message
                    key={m.id}
                    message={m}
                    disableThreadReply
                    chatInfo={{
                      id: "",
                      name: "",
                      profileUrl: "",
                      type: EdnaChatType.THREAD,
                    }}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
          <MessageComposer storeName="ednaThreadChats" />
        </section>
      )}
    </section>
  );
};

export default observer(ThreadConversation);
