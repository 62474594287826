import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../../stores";
import clsx from "clsx";
import styles from "./Mentions.module.scss";
import useMobileResponsive from "../../../../hooks/useMobileResponsive";
import { useHistory } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import Message from "./Message";
import { EdnaChatType, EdnaMessage } from "../../../../models/EdnaChat";
import locale from "../../../../constants/locale";
import { useTranslation } from "react-i18next";
import { ChatInterface } from "../../../../hooks/useTeammates";

const Mentions = ({ chats }: { chats: ChatInterface[] }) => {
  const { t } = useTranslation();
  const { liveChatCards } = useStore("users");
  const { loadChatMentions, chatMentions } = useStore("ednaChats");

  const auth = useStore("auth");
  const isMobile = useMobileResponsive();
  const [pageSize, setPageSize] = useState(200);
  const history = useHistory();

  useEffect(() => {
    const load = async () => {
      await loadChatMentions();
    };

    load();

    const handle = setInterval(load, 15000, false);

    return () => clearInterval(handle);
  }, [loadChatMentions]);

  const allChats = [
    ...chats,
    ...(liveChatCards?.map((c) => {
      return {
        id: String(c.id),
        name: c.name,
        image: undefined,
        type: EdnaChatType.CHANNEL,
      };
    }) || []),
  ];

  return (
    <section
      id="section-conversation"
      className={clsx(
        "full-screen-mobile is-flex is-flex-grow-1 is-flex-direction-column has-border-right",
        styles.container,
        {
          "pb-3 chat-bg": !isMobile,
          "is-active": true,
        }
      )}
    >
      <div className={clsx("has-background-light has-border", styles.header)}>
        <div className="is-flex is-align-items-center">
          {isMobile && (
            <button
              className="button is-ghost no-focus"
              onClick={() => history.goBack()}
            >
              <span className="icon">
                <img src="/assets/chevron-left.svg" alt="" />
              </span>
            </button>
          )}
          <h4 className="title is-6 my-2">{t(locale.mentions)}</h4>
        </div>
      </div>
      <div className="is-flex px-4 is-flex-direction-column is-align-items-stretch is-justify-content-flex-end is-flex-grow-1 is-relative">
        <div id="messages_scroll" className={clsx(styles.scrollContainer)}>
          <InfiniteScroll
            style={{ overflowX: "hidden" }}
            dataLength={chatMentions?.length || 0}
            next={() => setPageSize(pageSize + 50)}
            hasMore={pageSize === chatMentions?.length}
            loader={null}
            scrollableTarget="messages_scroll"
            className="is-flex is-flex-direction-column"
          >
            {chatMentions.map((m, index) => {
              const chatInterface = allChats.find((chat) => {
                let room;

                if (chat.type === EdnaChatType.AI_TEAM) {
                  room = `ai-team-${chat.id}`;
                } else if (chat.type === EdnaChatType.CHANNEL) {
                  room = "livecard-" + chat.id;
                } else {
                  room = [chat.id, auth.user?.id || ""]
                    .sort((a, b) => a.localeCompare(b))
                    .join("|");
                }
                return room === m.chat?.room;
              });

              return (
                <Message
                  key={index}
                  message={m.message as EdnaMessage}
                  chat={m.chat}
                  chatInfo={{
                    id: m.chatId,
                    name: chatInterface?.name || m.chat?.extras?.name,
                    profileUrl:
                      chatInterface?.image || m.chat?.extras?.profileUrl,
                    type: m.chat?.type as never,
                  }}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </section>
  );
};

export default observer(Mentions);
