import { Form, Formik } from "formik";
import { toast } from "bulma-toast";
import { useTranslation } from "react-i18next";
import locale from "../../../constants/locale";
import * as yup from "yup";
import { AntSelect, Field, Modal } from "../../../components";
import { useStore } from "../../../stores";
import { observer } from "mobx-react-lite";

import {
  BoardColummType,
  BoardColumn,
  ForecastCategory,
  boardColumnTypeMap,
  forecastCategoryMap,
} from "../../../models";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  mode: "add" | "edit";
  column?: BoardColumn;
};

const ColumnModal = ({ isOpen, onClose, mode, column }: Props) => {
  const { t } = useTranslation();
  const { selectedBot } = useStore("bots");
  const { selectedBoard, createColumn, updateColumn } = useStore("boards");
  const { user } = useStore("auth");

  const schema = yup.object().shape({
    name: yup.string().required(t(locale.fieldRequired)),
    type: yup.string().required(t(locale.fieldRequired)),
    probability: yup.number().min(0).max(100).required(t(locale.fieldRequired)),
    forecastCategory: yup.string().required(t(locale.fieldRequired)),
    description: yup.string().max(150).nullable(),
    columnSorting: yup.number().required(t(locale.fieldRequired)),
  });

  if (!selectedBot || !user) {
    return null;
  }

  const onSubmit = async (data: any) => {
    if (!selectedBoard) {
      return;
    }
    try {
      if (mode === "edit" && column) {
        await updateColumn({ ...column, ...data });
      } else {
        await createColumn(data);
      }

      onClose();
    } catch (error) {
      if (error instanceof Error) {
        toast({
          message: error.message,
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
      console.log(error);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        name: undefined,
        type: undefined,
        probability: undefined,
        forecastCategory: undefined,
        description: undefined,
        columnSorting: undefined,
        ...column,
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
        <Modal
          isOpen={isOpen}
          title={t(mode) + " " + t(locale.stage)}
          onClose={async () => {
            resetForm();
            onClose();
          }}
          isLargeScreen
          primaryText={t(locale.save)}
          primaryLoading={isSubmitting}
          onPrimary={isSubmitting ? undefined : submitForm}
        >
          <Form className="columns is-multiline">
            <div className="column is-half">
              <Field
                label={t(locale.name)}
                name="name"
                className="custom-input"
                disabled={isSubmitting}
              />
            </div>
            <div className="column is-half">
              <AntSelect
                showSearch
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                name="type"
                label={t(locale.type)}
                allowClear
                disabled={isSubmitting}
                options={Object.keys(boardColumnTypeMap).map((f) => ({
                  value: f,
                  label: boardColumnTypeMap[f as BoardColummType],
                }))}
              />
            </div>
            <div className="column is-half">
              <Field
                label={t(locale.probability)}
                name="probability"
                type="number"
                inputProps={{
                  min: 1,
                  max: 100,
                }}
                className="custom-input"
                disabled={isSubmitting}
              />
            </div>
            <div className="column is-half">
              <AntSelect
                showSearch
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                name="forecastCategory"
                label={t(locale.forecastCategory)}
                allowClear
                disabled={isSubmitting}
                options={Object.keys(forecastCategoryMap).map((f) => ({
                  value: f,
                  label: forecastCategoryMap[f as ForecastCategory],
                }))}
              />
            </div>
            <div className="column is-half">
              <Field
                label={t(locale.sorting)}
                name="columnSorting"
                className="custom-input"
                disabled={isSubmitting}
                type="number"
              />
            </div>
            <div className="column">
              <Field
                label={t(locale.description)}
                name="description"
                useTextArea
                className="custom-input"
                inputProps={{
                  maxLength: 150,
                  style: { height: 135, resize: "none" },
                }}
                disabled={isSubmitting}
              />
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default observer(ColumnModal);
