import { Suspense, useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useIdleTimer } from "react-idle-timer";

import {
  ErrorBoundary,
  GuardedRoute,
  NavBar,
  ScreenLoader,
} from "./components";

import routes, { notExact, publicRoutes } from "./constants/routes";

import getConfig from "./services/remoteConfig";

import { useStore } from "./stores";
import { getPage } from "./pages";
import SessionTimeout from "./modals/SessionTimeout";
import WorkspaceSidebar from "./components/workspaceSidebar/WorkspaceSidebar";
import BottomNavigation from "./components/navBar/BottomNavigation";
import TourGuider from "./components/tourGuider/TourGuider";
import { TwilioVoiceCallProvider } from "./components";

const USER_CAN_BE_SIGNED_FOR_MINUTES = 43800; //one month

const App = observer(() => {
  const auth = useStore("auth");
  const settings = useStore("settings");
  const { loadTicketsCount } = useStore("chats");
  const { loadNewMessageAlert } = useStore("ednaChats");
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(240); // Minutes / Changed from 15 to 240 minutes

  useIdleTimer({
    timeout: 60000 * sessionTimeout, // Convert to milliseconds
    onIdle: () => auth.user?.bots?.length && setShowSessionTimeout(true),
  });

  const handlePersistSessionLimitExceed = useCallback(() => {
    const loginAt = localStorage.getItem("login_at");

    if (!loginAt) {
      return;
    }

    //@ts-ignore
    const diffInMS = Math.abs(new Date() - new Date(loginAt));

    var minutes = Math.floor(diffInMS / 1000 / 60);
    if (minutes >= USER_CAN_BE_SIGNED_FOR_MINUTES) {
      localStorage.setItem("logout_info", "Logout after " + minutes);
      auth.logOut();
    }
  }, [auth]);

  useEffect(() => {
    const isPersistent = localStorage.getItem("remember_me") === "yes";

    settings.setAuthPersist(isPersistent);

    if (isPersistent && auth.user?.id) {
      handlePersistSessionLimitExceed();
    }

    getConfig("session_timeout").then((value) =>
      setSessionTimeout(value.asNumber())
    );
  }, [auth.user, settings, handlePersistSessionLimitExceed]);

  const handleSessionTimeoutClose = useCallback(
    () => setShowSessionTimeout(false),
    []
  );

  useEffect(() => {
    loadNewMessageAlert();
    loadTicketsCount();

    const x = setInterval(loadNewMessageAlert, 10000, true);
    const y = setInterval(loadTicketsCount, 10000, true);

    return () => {
      clearInterval(x);
      clearInterval(y);
    };
  }, [loadNewMessageAlert, loadTicketsCount]);

  if (!auth.initialized) {
    return null;
  }

  let homeRoute = routes.signIn;

  if (auth.user) {
    if (auth.user.workspaceIds?.length) {
      homeRoute = routes.workspaceHome.replace(
        ":workspaceId",
        String(auth.user.workspaceIds[0])
      );
    } else {
      homeRoute = routes.noWorkspace;
    }
  }

  return (
    <>
      <TourGuider />
      <Router>
        <Suspense fallback={<ScreenLoader />}>
          <ErrorBoundary>
            <TwilioVoiceCallProvider />
            <WorkspaceSidebar />
            <NavBar />
            <Suspense fallback={<ScreenLoader />}>
              <Switch>
                <Route exact path={routes.home}>
                  <Redirect to={homeRoute} />
                </Route>
                {Object.values(routes).map((route) => (
                  <GuardedRoute
                    key={route}
                    guard={!publicRoutes.includes(route)}
                    path={route}
                    exact={!notExact.includes(route)}
                    render={getPage(route)}
                  />
                ))}
                <Redirect to={routes.notFound} />
              </Switch>
            </Suspense>
            {showSessionTimeout && !!auth.user?.bots?.length && (
              <SessionTimeout onClose={handleSessionTimeoutClose} />
            )}
            <BottomNavigation />
          </ErrorBoundary>
        </Suspense>
      </Router>
    </>
  );
});

export default App;
