/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Field, Modal, AntSelect, DatePicker } from "../../../components";
import locale from "../../../constants/locale";
import {
  BoardColummType,
  BoardItemCard,
  channelMap,
  Chat,
  UserType,
} from "../../../models";
import { useStore } from "../../../stores";
import { Button, Divider, Spin } from "antd";
import { debounce } from "lodash";
import { getContacts } from "../../../services/chathub";
import { FaPlusSquare } from "react-icons/fa";
import AddContact from "../../contacts/components/AddContact";
import { useHistory, useLocation } from "react-router";
import ColumnModal from "./ColumnModal";
import { onlyDecimalNumbers } from "../../../utils/numbers";
import { toast } from "bulma-toast";
import collect from "collect.js";
import clsx from "clsx";
import Comments from "./comments/Comments";
import CustomFields from "./CustomFields";
import ChatQuickView from "../../chats/components/ChatQuickView";

const ItemModal = ({
  defaultOpen,
  disabled,
  onClose,
  item,
  columnId,
}: {
  disabled?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
  item?: BoardItemCard;
  columnId?: number;
}) => {
  const { t } = useTranslation();
  const { user, getPermissions } = useStore("auth");
  const {
    selectedBoard,
    addItemToBoard,
    updateItemToBoard,
    deleteItemFromBoard,
    archivedItem,
    setSelectedBoard,
  } = useStore("boards");
  const { users } = useStore("users");
  const [open, setOpen] = useState(defaultOpen ?? false);
  const [deletingItem, setDeletingItem] = useState(false);
  const [archivingItem, setArchivingItem] = useState(false);
  const [tab, setTab] = useState("comments");
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const [stageId, setStageId] = useState(columnId);
  const paramCommentId = queryParams.get("commentId");

  const boardColumns = useMemo(
    () =>
      collect(selectedBoard?.columns)
        .mapWithKeys((f: any) => [f.id, f.name])
        .all() as any,
    [selectedBoard]
  );

  const hasClosedLost = useMemo(
    () =>
      selectedBoard?.columns?.find((f) => f.id === stageId)?.type ===
      BoardColummType.ClosedLost,
    [selectedBoard, stageId]
  );

  const deleteItem = async () => {
    if (!item) return;
    setDeletingItem(true);
    try {
      await deleteItemFromBoard(item, columnId as never);
      onClose && onClose();
    } catch (error) {
      setDeletingItem(false);
      if (error instanceof Error) {
        toast({
          message: t(locale.genericError),
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
    }
  };

  const onArchived = async () => {
    if (!item) return;
    setArchivingItem(true);
    try {
      await archivedItem(item);
      onClose && onClose();
    } catch (error) {
      setArchivingItem(false);
      if (error instanceof Error) {
        toast({
          message: t(locale.genericError),
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
    }
  };

  const isCollaborator = item && user?.type === UserType.Collaborator;

  const collaboratorCanDelete = useMemo(
    () => getPermissions()?.canCreateOrDeleteDeals,
    [getPermissions]
  );

  useEffect(() => {
    if (open && paramCommentId && item?.comments?.length) {
      const comment = item.comments.find((f) => f.id === paramCommentId);
      if (comment) {
        setTimeout(() => {
          document
            .getElementById("ticket-comment-" + comment.id)
            ?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          const { pathname } = location;
          history.push(pathname);
        }, 200);
      }
    }
  }, [
    open,
    paramCommentId,
    item?.comments,
    item?.comments?.length,
    location,
    history,
  ]);

  return (
    <>
      {!defaultOpen && (
        <button
          disabled={disabled}
          className="button is-rounded is-ghosted"
          onClick={() => setOpen(true)}
        >
          <div className="icon" style={{ position: "absolute" }}>
            <img src="/assets/add-blue.svg" alt="" />
          </div>
        </button>
      )}
      <Formik
        initialValues={{
          name: "",
          subscriberId: undefined,
          assigneeId: undefined,
          ...item,
          dueDate: item?.dueDate ? item.dueDate.toJSDate() : undefined,
          columnId,
          closedLostReason: item?.closedLostReason ?? "",
          customFields: item?.customFields?.map((f) => ({
            ...f,
            value: f.type === "date" ? new Date(f.value) : f.value,
          })),
        }}
        validationSchema={yup.object({
          name: yup.string().required(t(locale.fieldRequired)),
          columnId: yup.number().required(t(locale.fieldRequired)),
          subscriberId: yup.string().required(t(locale.fieldRequired)),
          dueDate: yup.date().required(t(locale.fieldRequired)),
          assigneeId: yup.string().required(t(locale.fieldRequired)),
          opportunity: yup.number().required(t(locale.fieldRequired)),
          ...(hasClosedLost && {
            closedLostReason: yup
              .string()
              .max(30)
              .required(t(locale.fieldRequired)),
          }),
          customFields: yup.array().of(
            yup.object({
              value: yup.string().nullable().required(t(locale.requiredField)),
            })
          ),
        })}
        onSubmit={async (data, { resetForm, setFieldValue }) => {
          try {
            if (item?.id) {
              if (selectedBoard && columnId !== data.columnId) {
                const sourceColumn = selectedBoard?.columns?.find(
                  (f) => f.id === columnId
                );
                const destinationColumn = selectedBoard?.columns?.find(
                  (f) => f.id === data.columnId
                );
                // If source is different from destination, we need to update multiple columns
                const sourceItems = sourceColumn?.items ?? [];
                const sourceItem = sourceItems.find((f) => f.id === item.id);
                if (!sourceItem) return;

                // Filter the start list like before
                const newStartList =
                  sourceColumn?.items?.filter((f) => f.id !== data.id) ?? [];

                // Make a new end list array
                const newEndList = destinationColumn?.items ?? [];

                // Insert the item into the end list
                newEndList.push(sourceItem);
                setSelectedBoard({
                  ...selectedBoard,
                  columns: selectedBoard?.columns?.map((col) => {
                    if (col.uuid === sourceColumn?.uuid) {
                      return {
                        ...col,
                        items: newStartList,
                      };
                    } else if (col.uuid === destinationColumn?.uuid) {
                      return {
                        ...col,
                        items: newEndList,
                      };
                    }
                    return col;
                  }),
                });
              }
              await updateItemToBoard(
                {
                  ...item,
                  ...data,
                  opportunity: parseFloat(
                    data.opportunity as unknown as string
                  ),
                  updatedAt: new Date(),
                } as any,
                data.columnId as never,
                columnId as never,
                item?.assigneeId
              );
            } else {
              await addItemToBoard(
                {
                  type: "card",
                  ...data,
                  opportunity: parseFloat(
                    data.opportunity as unknown as string
                  ),
                } as any,
                data.columnId as never
              );
            }
            setOpen(false);
            resetForm();
            setFieldValue("opportunity", "");
            if (onClose) {
              onClose();
            }
          } catch (error: any) {
            console.log("error", error);
            let message = t(locale.genericError);
            if (error?.status === 400) {
              message = error.response.data.error;
            }
            toast({
              message,
              position: "top-right",
              dismissible: true,
              type: "is-danger",
              pauseOnHover: true,
            });
          }
        }}
      >
        {({ isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
          <Modal
            isOpen={open}
            title={t(locale.deal)}
            onClose={() => {
              setOpen(false);
              resetForm();
              if (onClose) {
                onClose();
              }
            }}
            primaryText={t(item ? locale.update : locale.save)}
            primaryLoading={isSubmitting}
            onPrimary={isSubmitting ? undefined : submitForm}
            isLargeScreen
          >
            <Form>
              <div className="columns is-multiline">
                <div className="column is-half">
                  <Field
                    name="name"
                    label={t(locale.name)}
                    placeholder={"Enter " + t(locale.name)}
                    className="custom-input"
                    disabled={isSubmitting || isCollaborator}
                  />
                </div>
                <div className="column is-half">
                  <StageSelector
                    disabled={isSubmitting}
                    onChange={setStageId}
                  />
                </div>
                <div className="column is-half">
                  <AccountSelector
                    subcriber={item?.subscriber}
                    disabled={isSubmitting || isCollaborator || disabled}
                    name="subscriberId"
                  />
                </div>
                <div className="column is-half">
                  <DatePicker
                    name="dueDate"
                    label={t(locale.estimatedCloseDate)}
                    disabled={isSubmitting}
                    minDate={new Date()}
                    inputClass="custom-input"
                    wrapperClassName="w-100"
                    showTimeInput
                    dateFormat="MM/dd/yyyy h:mm aa"
                  />
                </div>
                <div className="column is-half">
                  <AntSelect
                    showSearch
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    name="assigneeId"
                    label={t(locale.assignee)}
                    allowClear
                    options={
                      users?.map((f) => ({
                        value: f.id,
                        label: f.name || f.email,
                      })) || []
                    }
                    disabled={isSubmitting || isCollaborator}
                  />
                </div>
                <div className="column is-half">
                  <Field
                    name="opportunity"
                    label={t(locale.opportunity)}
                    placeholder={"Enter " + t(locale.opportunity)}
                    className="custom-input"
                    disabled={isSubmitting}
                    inputProps={{
                      onKeyDown: onlyDecimalNumbers,
                    }}
                  />
                </div>
                {hasClosedLost && (
                  <div className="column is-half">
                    <Field
                      name="closedLostReason"
                      label={t(locale.reason)}
                      placeholder={"Enter " + t(locale.reason)}
                      className="custom-input"
                      disabled={isSubmitting}
                      inputProps={{
                        maxLength: 30,
                      }}
                      showCounter
                    />
                  </div>
                )}
              </div>

              {item && (
                <div className="w-100 has-text-right">
                  <button
                    onClick={onArchived}
                    disabled={
                      archivingItem ||
                      isSubmitting ||
                      (isCollaborator && !collaboratorCanDelete)
                    }
                    className="button mr-2 p-0 is-no-box-shadow is-ghost has-text-primary"
                  >
                    {item?.archivedAt ? t(locale.remove) : ""}{" "}
                    {t(locale.archived)}
                  </button>
                  <button
                    onClick={deleteItem}
                    disabled={
                      deletingItem ||
                      isSubmitting ||
                      (isCollaborator && !collaboratorCanDelete)
                    }
                    className="button p-0 is-no-box-shadow is-ghost has-text-danger"
                  >
                    {t(locale.delete)}
                  </button>
                </div>
              )}
              <div className="column is-full" style={{ minHeight: "16rem" }}>
                <div className="tabs">
                  <ul>
                    <li className={clsx({ "is-active": tab === "comments" })}>
                      <a onClick={() => setTab("comments")}>
                        {t(locale.comments)}
                      </a>
                    </li>
                    {!!item && (
                      <li className={clsx({ "is-active": tab === "history" })}>
                        <a onClick={() => setTab("history")}>
                          {t(locale.history)}
                        </a>
                      </li>
                    )}
                    <li
                      className={clsx({ "is-active": tab === "customFields" })}
                    >
                      <a onClick={() => setTab("customFields")}>
                        {t(locale.customFields)}
                      </a>
                    </li>
                  </ul>
                </div>

                {tab === "comments" && (
                  <>
                    {item?.id ? (
                      <Comments boardItem={item} />
                    ) : (
                      <small>
                        {t(locale.commentWillBeAvailableAfterSaving)}
                      </small>
                    )}
                  </>
                )}
                {tab === "history" && (
                  <>
                    {item?.histories?.length && (
                      <ul>
                        {item.histories.map((f, i) => (
                          <li key={i}>
                            <span>
                              Move to <b>{boardColumns[f.currentStageId]}</b> at{" "}
                              <b>{f.addedAt.toFormat("M/d/yyyy, h:mm a")}</b>
                              {f.removedAt ? (
                                <>
                                  {" "}
                                  and removed at{" "}
                                  <b>
                                    {f.removedAt.toFormat("M/d/yyyy, h:mm a")}
                                  </b>
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
                {tab === "customFields" && (
                  <CustomFields
                    value={values?.customFields}
                    onChange={(v) => setFieldValue("customFields", v)}
                  />
                )}
              </div>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export const AccountSelector = ({
  disabled,
  subcriber,
  name,
}: {
  name: string;
  disabled?: boolean;
  subcriber?: Chat;
}) => {
  const { t } = useTranslation();
  const [chatQuickView, setChatQuickView] = useState(0);
  const [open, setOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const { selectedBot } = useStore("bots");
  const [options, setOptions] = useState<
    { key?: string; label: React.ReactNode; value: string | number }[]
  >([]);
  const fetchRef = useRef(0);
  const debounceTimeout = 800;

  // const chatsTakenIds = useMemo(
  //   () =>
  //     boards
  //       ?.flatMap((f) => f.columns)
  //       ?.flatMap((f) => f.list)
  //       ?.map((f) => f.chat?.value) ?? [],
  //   [boards]
  // );

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      setOptions([]);

      if (!selectedBot || value.length <= 2) {
        return;
      }

      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);

      getContacts(
        selectedBot.id,
        selectedBot.key,
        selectedBot.password,
        value,
        undefined,
        1,
        0
      ).then((chats) => {
        chats = chats as Chat[];
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(
          chats.map((m) => ({
            label: `${m.firstName} ${m.lastName ?? ""} - ${
              channelMap[m.channel as never] ?? t(locale.other)
            } ${
              m.data?.e_cus_otherChannel ? `(${m.data.e_cus_otherChannel})` : ""
            }`,
            value: m.id,
            // disabled: chatsTakenIds.includes(m.id),
          }))
        );
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [selectedBot, t]);

  const openChat = (id: any) => {
    setChatQuickView(id);
  };

  useEffect(() => {
    if (subcriber?.firstName) {
      const name = `${subcriber.firstName} ${subcriber.lastName ?? ""} - ${
        channelMap[subcriber.channel as never] ?? t(locale.other)
      }`;
      setOptions([{ label: name, value: subcriber.id }]);
    }
  }, [subcriber, t]);

  return (
    <>
      {!open && (
        <AntSelect
          showSearch
          filterOption={false}
          name={name}
          label={
            <div>
              <span>{t(locale.contact)}</span>
              {subcriber?.id && (
                <a onClick={() => openChat(subcriber.id)} className="ml-1">
                  {t(locale.openChat)}
                </a>
              )}
            </div>
          }
          allowClear
          disabled={disabled}
          onSearch={debounceFetcher}
          // labelInValue
          notFoundContent={
            fetching ? (
              <Spin size="small" />
            ) : (
              <span className="px-2">{t(locale.notFound)}</span>
            )
          }
          options={options}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <div className="is-flex is-justify-content-center w-100">
                <Button
                  type="text"
                  className="is-flex is-align-items-center"
                  onClick={(e) => {
                    setOpen(true);
                  }}
                  icon={<FaPlusSquare />}
                >
                  {t(locale.create)}
                </Button>
              </div>
            </>
          )}
        />
      )}

      {open && <AddContact defaultOpen onClose={() => setOpen(false)} />}
      {chatQuickView > 0 && (
        <ChatQuickView
          chatId={chatQuickView}
          onClose={() => setChatQuickView(0)}
        />
      )}
    </>
  );
};

const StageSelector = ({
  disabled,
  onChange,
}: {
  disabled?: boolean;
  onChange?: (v: any) => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { selectedBoard } = useStore("boards");

  return (
    <>
      {!open && (
        <AntSelect
          showSearch
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          name="columnId"
          label={t(locale.stage)}
          allowClear
          disabled={disabled}
          options={
            selectedBoard?.columns?.map((f) => ({
              value: f.id,
              label: f.name,
            })) || []
          }
          onChange={onChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <div className="is-flex is-justify-content-center w-100">
                <Button
                  type="text"
                  className="is-flex is-align-items-center"
                  onClick={(e) => {
                    setOpen(true);
                  }}
                  icon={<FaPlusSquare />}
                >
                  {t(locale.create)}
                </Button>
              </div>
            </>
          )}
        />
      )}
      {open && (
        <ColumnModal isOpen onClose={() => setOpen(false)} mode={"add"} />
      )}
    </>
  );
};

export default observer(ItemModal);
