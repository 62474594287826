import { toast } from "bulma-toast";
import clsx from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import locale from "../../../constants/locale";

import styles from "./MessageComposer.module.scss";

const MAX_ALLOWED_SIZE = 2 * 1024 * 1024; // 2MB

type Props = {
  storeName?: "ednaChats" | "ednaThreadChats";
  isLoading: boolean;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setShowRecorder: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AddAttachment({
  storeName,
  isLoading,
  setFiles,
  setShowRecorder,
}: Props) {
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const hasSizeLimit = useMemo(
    () => (blob: Blob) => {
      if (blob.size > MAX_ALLOWED_SIZE) {
        toast({
          message: t(locale.fileIsTooLarge, { size: `2MB` }),
          position: "top-right",
          dismissible: true,
          type: "is-warning",
          pauseOnHover: true,
        });
        return true;
      }

      return false;
    },
    [t]
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { files } = target;

      if (files) {
        for (const file of files) {
          if (
            ![
              "audio/mpeg",
              "audio/mp3",
              "audio/ogg",
              "audio/amr",
              "image/jpeg",
              "image/png",
              "video/mp4",
              "application/pdf",
            ].includes(file.type)
          ) {
            toast({
              message: t(locale.fileHasNoSupportedFormat),
              position: "top-right",
              dismissible: true,
              type: "is-warning",
              pauseOnHover: true,
            });
            return;
          }

          if (hasSizeLimit(file)) {
            return;
          }
        }

        setFiles(files as unknown as File[]);
      }
      setIsPopoverOpen(false);
    },
    [setFiles, hasSizeLimit, t]
  );

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["top"]}
      containerClassName={styles.popOver}
      padding={7}
      parentElement={
        document.getElementById(
          storeName === "ednaChats"
            ? "section-edna-conversation"
            : "section-thread-conversation"
        ) ?? undefined
      }
      onClickOutside={() => setIsPopoverOpen(false)}
      content={() => (
        <div>
          <div
            className={clsx(
              styles.attachmentContainer,
              "is-flex is-flex-direction-column mb-4"
            )}
          >
            <input
              id="image-video-picker"
              type="file"
              className="is-hidden"
              accept="image/jpeg,image/png,video/mp4"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="image-video-picker" className="button is-ghost p-0">
              <span className="">
                <img src="/assets/file-picker.svg" alt="" />
              </span>
            </label>
            <input
              id="audio-picker"
              type="file"
              className="is-hidden"
              accept=".mp3,.amr,audio/ogg"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="audio-picker" className="button is-ghost p-0">
              <span className="">
                <img src="/assets/audio-picker.svg" alt="" />
              </span>
            </label>
            <button
              className="button is-ghost p-0"
              disabled={!(window as any).MediaRecorder}
              onClick={() => {
                setShowRecorder(true);
                setIsPopoverOpen(false);
              }}
            >
              <span className="">
                <img src="/assets/mic-picker.svg" alt="" />
              </span>
            </button>
            <input
              id="file-picker"
              type="file"
              className="is-hidden"
              accept=".pdf"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="file-picker" className="button is-ghost p-0">
              <span className="">
                <img src="/assets/document-picker.svg" alt="" />
              </span>
            </label>
          </div>
        </div>
      )}
    >
      <button
        className={clsx("button is-ghost is-no-box-shadow has-text-primary", {
          "is-loading": isLoading,
        })}
        onClick={() => {
          setIsPopoverOpen(!isPopoverOpen);
        }}
      >
        <span className="icon">
          {/* {files || showRecorder ?
                        <i
                            className={clsx("fas", {
                                "fa-arrow-right": value || files || showRecorder,
                            })}></i>
                        : */}
          <img src="/assets/attachment-add.svg" alt="attach" />
          {/* } */}
        </span>
      </button>
    </Popover>
  );
}
