import clsx from 'clsx';
import { useCallback } from 'react';
import { LocalAudio, LocalImage, LocalVideo, Recorder } from '../../../components';
import styles from "./MessageComposer.module.scss";

type Props = {
  files: File[];
  onRemove: () => void;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  showRecorder: boolean;
  setShowRecorder: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PreviewFiles({ files, setFiles, onRemove, showRecorder, setShowRecorder }: Props) {
  const previews = [...files].map((file, i) => {
    switch (file.type) {
      case "image/png":
      case "image/jpeg":
        return (
          <div
            key={i}
            className={clsx("image has-rounded-corners mr-1", styles.image)}
          >
            <LocalImage file={file} alt="media" />
          </div>
        );
      case "video/mp4":
        return (
          <div
            key={i}
            className={clsx(
              "image is-16by9 has-rounded-corners mr-1",
              styles.video
            )}
          >
            <LocalVideo className="has-ratio" file={file} controls />
          </div>
        );
      case "audio/mp3":
      case "audio/mpeg":
      case "audio/ogg":
      case "audio/amr":
        return (
          <div key={i} className="mr-1">
            <LocalAudio file={file} />
          </div>
        );
      case "application/pdf":
        return (
          <div
            key={i}
            className="is-flex has-rounded-corners has-background-light px-2 mr-1"
          >
            <span className="icon mr-2">
              <i className="fas fa-file-pdf"></i>
            </span>
            <span className="has-ellipsis-text">{(file as File).name}</span>
          </div>
        );
      default:
        return null;
    }
  });

  const handleRecording = useCallback((blob: Blob) => {
    setFiles([blob as File]);
    setShowRecorder(false);
  }, [setFiles, setShowRecorder]);

  if (showRecorder) {
    return (
      <div className={clsx("input is-align-items-center", styles.input)}>
        <Recorder onRecordingAvailable={handleRecording} />
        <span
          className="delete ml-5"
          onClick={() => setShowRecorder(false)}
        ></span>
      </div>
    )
  }

  if (!previews.length) {
    return null;
  }

  return (
    <div className={clsx("input is-scrollable-x", styles.input)}>
      {previews}
      <span className="delete ml-1" onClick={onRemove}></span>
    </div>
  );
}
