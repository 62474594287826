import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { FallbackImage } from "../../../components";
import styles from "./Chat.module.scss";
import { useStore } from "../../../stores";
import routes from "../../../constants/routes";
import { userStatusColorMap } from "../../../models";
import { ChatInterface } from "../../../hooks/useTeammates";

const Chat = ({
  chat,
  newMessage,
  disableOnClick,
}: {
  chat: ChatInterface;
  newMessage?: boolean;
  disableOnClick?: boolean;
}) => {
  const { selectedWorkspace } = useStore("workspaces");
  const history = useHistory();

  const { userId } = useParams<{
    userId?: string;
  }>();

  return (
    <div
      className={clsx(
        "is-relative is-flex w-100 px-3 py-2 is-list-item is-clickable",
        {
          "is-active": userId === chat.id,
        }
      )}
      onClick={() => {
        if (disableOnClick) return;
        history.push(
          routes.teammates
            .replace(":workspaceId", String(selectedWorkspace?.id))
            .replace(":userId", chat.id || "")
        );
      }}
    >
      <figure className="is-flex-shrink-0">
        <p className="image is-40x40 is-square">
          <FallbackImage
            className="is-rounded"
            src={chat.image}
            fallback="/assets/avatar.svg"
            alt={chat.name}
            title={chat.name}
          />
          {chat.userStatus && (
            <div
              className={clsx("ml-1 ", styles.status)}
              style={{
                background: userStatusColorMap[chat.userStatus as never],
              }}
            />
          )}
        </p>
      </figure>
      <div className="is-flex is-flex-grow-1 is-align-items-stretch is-justify-content-center is-flex-direction-column ml-3">
        <div className="is-flex is-justify-content-space-between">
          <strong
            title={chat.name}
            className={clsx("pr-3 has-ellipsis-text", styles.userName)}
          >
            {chat.name}
          </strong>
          <div className="is-flex is-align-items-center">
            {/* <small className={clsx("has-text-grey", styles.date)}>
                {time}
              </small> */}
          </div>
        </div>
      </div>
      <RedDotBadge hasNewMessages={newMessage ?? false} />
    </div>
  );
};

const RedDotBadge = ({ hasNewMessages }: { hasNewMessages: boolean }) => {
  if (hasNewMessages) {
    return <span className={clsx("badge is-right mr-4 is-danger", {})}></span>;
  }
  return null;
};

export default Chat;
