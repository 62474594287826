import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../stores";

export default function RepliesCount({
  disableThreadReply,
  messageId,
  repliesCount,
}: {
  disableThreadReply?: boolean;
  messageId: string;
  repliesCount?: number;
}) {
  const { selectedChat } = useStore("ednaChats");
  const { firstOrCreateChat } = useStore("ednaThreadChats");
  const { t } = useTranslation();

  const openThread = () => {
    firstOrCreateChat(
      String(selectedChat?.room),
      String(selectedChat?.id),
      messageId
    );
  };

  if (!selectedChat?.id || disableThreadReply || !repliesCount) return null;

  return (
    <span onClick={openThread} className="ml-1 has-text-primary is-clickable">
      {t(repliesCount > 1 ? locale.countReplies : locale.countReply, {
        count: repliesCount,
      })}
    </span>
  );
}
