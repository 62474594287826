import { Modal } from "../..";
import { FaPhoneAlt, FaPhoneSlash } from "react-icons/fa";

export default function IncomingCallModal({
  payload,
  onAccept,
  onReject,
}: {
  onAccept: () => void;
  onReject: () => void;
  payload: {
    from: string;
  };
}) {
  return (
    <Modal isOpen title={() => <h2 className="title is-5">Incoming Call</h2>}>
      <div className="has-text-centered">
        <div className="is-flex is-justify-content-center mb-4">
          <div
            className="is-flex is-justify-content-center is-align-items-center has-background-info-light"
            style={{ width: "64px", height: "64px", borderRadius: "50%" }}
          >
            <FaPhoneAlt className="has-text-info" size={32} />
          </div>
        </div>

        <p className="is-size-5 mb-5">
          Call from <strong>{payload.from}</strong>
        </p>

        <div
          className="is-flex is-justify-content-center"
          style={{ gap: "1rem" }}
        >
          <button
            className="button is-success is-flex is-align-items-center"
            onClick={onAccept}
          >
            <FaPhoneAlt size={20} className="mr-2" />
            <span>Accept</span>
          </button>
          <button
            className="button is-danger is-flex is-align-items-center"
            onClick={onReject}
          >
            <FaPhoneSlash size={20} className="mr-2" />
            <span>Reject</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}
