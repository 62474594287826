type NumberOptions = {
  currency?: string;
  style?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  showPlusAtStart?: boolean;
};
export const formatNumber = (number?: number, options?: NumberOptions) => {
  number = number || 0;

  options = {
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
    currency: options?.currency ?? "USD",
  };

  const formattedNumber = new Intl.NumberFormat("en-US", options as any).format(
    number
  );

  return (options.showPlusAtStart && number >= 0 ? "+" : "") + formattedNumber;
};

export const onlyNumbers = (e: any) => {
  const key = e.key;
  const keyCode = e.keyCode || e.which;

  // Allow numeric characters (0-9) and the backspace key
  if (!/^[0-9]+$/.test(key) && keyCode !== 8) {
    e.preventDefault();
  }
};

export const onlyDecimalNumbers = (e: any, allowDecimal = 2) => {
  const key = e.key;
  const inputElement = e.currentTarget;
  const currentValue = inputElement.value;

  // Allow backspace, arrow keys, home, end, delete
  if (
    ["Backspace", "ArrowLeft", "ArrowRight", "Home", "End", "Delete"].includes(
      key
    )
  ) {
    return;
  }

  // Prevent default for any key that's not a number or decimal point
  if (!/^[0-9.]$/.test(key)) {
    e.preventDefault();
    return;
  }

  // Prevent multiple decimal points
  if (key === "." && currentValue.includes(".")) {
    e.preventDefault();
    return;
  }

  // Limit decimal places
  const [, decimalPart] = currentValue.split(".");
  if (
    decimalPart &&
    decimalPart.length >= allowDecimal &&
    inputElement.selectionStart! > currentValue.indexOf(".")
  ) {
    e.preventDefault();
    return;
  }

  // Prevent leading zeros
  if (key === "0" && currentValue === "0") {
    e.preventDefault();
    return;
  }
};
