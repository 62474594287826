import { DateTime } from "luxon";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { FallbackImage } from "../../../components";
import { useStore } from "../../../stores";
import styles from "./Message.module.scss";
import {
  EdnaChatInfo,
  EdnaChatType,
  EdnaMessage,
  ednaBotUser,
} from "../../../models/EdnaChat";
import { MessageRenders } from "./MessageRenders/MessageRenders";
import ActionDropdown from "./ActionDropdown";
import RepliesCount from "./RepliesCount";

const Message = ({
  message: { id, userId, message, createdAt, repliesCount },
  chatInfo,
  disableThreadReply,
}: {
  message: EdnaMessage;
  chatInfo: EdnaChatInfo;
  disableThreadReply?: boolean;
}) => {
  const { selectedBot } = useStore("bots");
  const { users } = useStore("users");
  const auth = useStore("auth");
  let time = "--:--";

  try {
    //@ts-ignore
    const date = DateTime.fromSeconds(createdAt.seconds).plus(
      //@ts-ignore
      createdAt.nanoseconds / 1000000000
    );

    time = date.hasSame(DateTime.utc(), "day")
      ? date.toFormat("t")
      : date.toLocaleString({
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "2-digit",
        });
  } catch (error) {
    console.error(error, id, createdAt);
  }

  let user = users?.find((f) => f.id === userId);
  if (userId === ednaBotUser.id) {
    user = ednaBotUser;
  }
  const isFromUser = userId !== auth.user?.id;

  const getFallbackImage = () => {
    if (chatInfo?.type === EdnaChatType.AI_TEAM) {
      const agent = chatInfo.extras?.aIteamInfo?.agents?.find(
        (f: any) => f.name === userId
      );
      if (agent?.icon) {
        return agent.icon;
      }
    }

    return "/assets/avatar.svg";
  };

  const image = (
    <figure className="is-flex-shrink-0">
      <p className="image is-32x32 is-square">
        <FallbackImage
          className="is-rounded"
          src={user?.avatarUrl}
          fallback={getFallbackImage()}
          alt={user?.name || userId || "user"}
          title={user?.name || userId || "user"}
        />
      </p>
    </figure>
  );

  return (
    <article
      className={clsx("is-flex py-3 is-relative", {
        "is-justify-content-flex-end": !isFromUser,
        "is-justify-content-flex-start": isFromUser,
      })}
    >
      {isFromUser && image}
      <div
        className={clsx(
          {
            "ml-3": isFromUser,
            "mr-3": !isFromUser,
          },
          "is-flex is-flex-direction-column"
        )}
      >
        <div
          className={clsx(
            "is-relative is-flex is-flex-direction-column is-align-items-stretch",
            styles.container,
            {
              "bubble-left": isFromUser,
              "bubble-right": !isFromUser,
            }
          )}
        >
          <ActionDropdown
            disableThreadReply={disableThreadReply}
            messageId={String(id)}
            isFromUser={isFromUser}
            message={message}
          />
          <small
            className={clsx(
              "is-flex is-justify-content-space-between is-align-items-center",
              {
                "mb-2":
                  !isFromUser &&
                  (user?.name || (!isFromUser ? selectedBot?.name : "")),
              }
            )}
          >
            <strong className="mr-2">{user?.name || userId}</strong>
          </small>
          <span
            className={clsx(
              styles.font13,
              "has-long-text has-text-weight-medium"
            )}
          >
            <MessageRenders message={message} />
          </span>
        </div>
        <div
          className={clsx(styles.timeColor, {
            "has-text-right": !isFromUser,
          })}
        >
          {time}
          <RepliesCount
            messageId={String(id)}
            disableThreadReply={disableThreadReply}
            repliesCount={repliesCount}
          />
        </div>
      </div>
      <div></div>
      {!isFromUser && image}
    </article>
  );
};

export default observer(Message);
