import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Field, Modal } from "../../../components";
import { useStore } from "../../../stores";
import locale from "../../../constants/locale";
import { toast } from "bulma-toast";
import * as Yup from "yup";
import useTeammates from "../../../hooks/useTeammates";
import { EdnaChatType } from "../../../models";
import Chat from "../../teammates/components/Chat";
import { CollapseWrapper } from "../../teammates/Teammates";
import { useCallback, useState } from "react";

type Props = {
  message: any;
  isOpen: boolean;
  onClose: () => void;
};

export const ForwardModal = ({ message, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { selectedWorkspace } = useStore("workspaces");
  const auth = useStore("auth");
  const [search, setSearch] = useState("");

  const { chats } = useTeammates();
  const { sendForwardMessage } = useStore("ednaChats");

  const onSendMessage = useCallback(
    async (chatId: string) => {
      const chat = chats.find((f) => f.id === chatId);
      if (!chat) {
        return;
      }

      let room;

      if (chat.type === EdnaChatType.CHANNEL) {
        room = `livecard-${chat.id}`;
      } else {
        room = [chat.id, auth.user?.id || ""]
          .sort((a, b) => a.localeCompare(b))
          .join("|");
      }

      try {
        await sendForwardMessage(message, room, chat.type);
      } catch (error) {
        console.log(error);
        if (error instanceof Error) {
          toast({
            message: error.message,
            position: "top-right",
            dismissible: true,
            type: "is-danger",
            pauseOnHover: true,
          });
        }
      } finally {
      }
    },
    [auth.user?.id, chats, message, sendForwardMessage]
  );

  const filteredChats = chats.filter((f) => {
    if (!search) return true;
    return f.name.toLowerCase().includes(search.toLowerCase());
  });

  if (!selectedWorkspace) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        chatIds: [],
      }}
      validationSchema={Yup.object().shape({
        chatIds: Yup.array().min(1, "Please select at least one chat"),
      })}
      onSubmit={async (values: any) => {
        try {
          for (let chatId of values.chatIds) {
            await onSendMessage(chatId);
          }
          toast({
            message: t(locale.messageForwardSuccess),
            position: "top-right",
            dismissible: true,
            type: "is-success",
            pauseOnHover: true,
          });
          onClose();
        } catch (e) {
          console.log("error", e);
          toast({
            message: t(locale.genericError),
            position: "top-right",
            dismissible: true,
            type: "is-danger",
            pauseOnHover: true,
          });
        }
      }}
    >
      {({
        isSubmitting,
        submitForm,
        resetForm,
        values,
        setFieldValue,
      }: any) => (
        <Modal
          isOpen={isOpen}
          title={t(locale.forwardMessageTo)}
          onClose={() => {
            resetForm();
            onClose();
          }}
          primaryLoading={isSubmitting}
          primaryText={t(locale.send)}
          onPrimary={submitForm}
          allowCardOverflow
        >
          <div className="field">
            <label className="label">{t(locale.search)}</label>
            <div className="control">
              <input
                name="search"
                className="input"
                placeholder={t(locale.search)}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <Field name="chatIds" type="hidden" />
          {filteredChats.filter((f) =>
            [EdnaChatType.SINGLE, EdnaChatType.CHANNEL].includes(f.type)
          ).length === 0 && (
            <div className="has-text-grey">{t(locale.noResult)}</div>
          )}
          {filteredChats.filter((f) => f.type === EdnaChatType.SINGLE)?.length >
            0 && (
            <CollapseWrapper name={locale.humanTeammates}>
              {filteredChats
                .filter((f) => f.type === EdnaChatType.SINGLE)
                ?.map((chat) => {
                  return (
                    <SelectableChat
                      chat={chat}
                      isSelected={values.chatIds.includes(chat.id)}
                      onChange={() => {
                        if (values.chatIds.includes(chat.id)) {
                          setFieldValue(
                            "chatIds",
                            values.chatIds.filter((f: any) => f !== chat.id)
                          );
                        } else {
                          setFieldValue("chatIds", [
                            ...values.chatIds,
                            chat.id,
                          ]);
                        }
                      }}
                    />
                  );
                })}
            </CollapseWrapper>
          )}

          {filteredChats.filter((f) => f.type === EdnaChatType.CHANNEL).length >
            0 && (
            <CollapseWrapper
              name={locale.teams}
              key={
                filteredChats.filter((f) => f.type === EdnaChatType.CHANNEL)
                  .length
              }
            >
              {filteredChats
                .filter((f) => f.type === EdnaChatType.CHANNEL)
                ?.map((chat) => {
                  return (
                    <SelectableChat
                      chat={chat}
                      isSelected={values.chatIds.includes(chat.id)}
                      onChange={() => {
                        if (values.chatIds.includes(chat.id)) {
                          setFieldValue(
                            "chatIds",
                            values.chatIds.filter((f: any) => f !== chat.id)
                          );
                        } else {
                          setFieldValue("chatIds", [
                            ...values.chatIds,
                            chat.id,
                          ]);
                        }
                      }}
                    />
                  );
                })}
            </CollapseWrapper>
          )}
        </Modal>
      )}
    </Formik>
  );
};

const SelectableChat = ({ chat, isSelected, onChange }: any) => {
  return (
    <div className="mx-3 py-1 has-rounded-corners">
      <div className="control">
        <label className="checkbox w-100 is-flex is-flex is-align-items-center">
          <input
            type="checkbox"
            className="mr-2"
            onChange={onChange}
            checked={isSelected}
          />
          <Chat chat={chat} disableOnClick />
        </label>
      </div>
    </div>
  );
};
