export const getContentType = async (url: string) => {
  try {
    const result = await fetch(url, {
      method: "HEAD",
    });

    return result.headers.get("Content-Type");
  } catch (error) {
    return "";
  }
};
