/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import { Loader, Modal } from "../../../components";
import locale from "../../../constants/locale";
import clsx from "clsx";
import useMobileResponsive from "../../../hooks/useMobileResponsive";
import styles from "./Conversation.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCallback, useEffect, useRef, useState } from "react";
import Message from "./Message";
import { getChatMessages } from "../../../services/chathub";
import { useStore } from "../../../stores";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";

const ChatQuickView = ({
  chatId,
  onClose,
}: {
  chatId: number;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { selectedBot, routeTo } = useStore("bots");
  const isMobile = useMobileResponsive();
  const [messages, setMessages] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(200);
  const [loading, setLoading] = useState(true);
  const divRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const load = useCallback(async () => {
    if (!selectedBot) return;

    if (pageSize === 200) {
      setLoading(true);
    }
    const messages = await getChatMessages(
      selectedBot.id,
      selectedBot.key,
      selectedBot.password,
      chatId,
      pageSize
    );
    setMessages(messages);
    if (divRef.current) {
      setTimeout(() => {
        if (divRef.current) {
          divRef.current.scrollTop = divRef.current.scrollHeight;
        }
      }, 1000);
    }
    setLoading(false);
  }, [pageSize, selectedBot, chatId]);

  useEffect(() => {
    if (!chatId) {
      return;
    }

    load();
  }, [chatId, load, pageSize]);

  const openChat = () => {
    history.push(routeTo(routes.chats).replace(":chatId?", `${chatId}`));
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen
      title={() => (
        <div className="is-flex">
          <span className="is-5 title">{t(locale.chat)}</span>
          <a onClick={() => openChat()} className="ml-1">
            {t(locale.openFullView)}
          </a>
        </div>
      )}
      isMediumScreen
    >
      <div
        className="is-flex is-relative"
        style={{
          height: "100%",
        }}
      >
        <section
          id="section-conversation"
          className={clsx(
            "full-screen-mobile is-flex is-flex-grow-1 is-flex-direction-column has-border-right",
            styles.container,
            {
              "pb-3 chat-bg": !isMobile,
              "is-active": true,
            }
          )}
        >
          <div className="is-flex is-flex-direction-column is-align-items-stretch is-justify-content-flex-end is-flex-grow-1 is-relative">
            <div
              ref={divRef}
              id="messages_scroll"
              className={clsx(styles.scrollContainer)}
            >
              <InfiniteScroll
                style={{ overflowX: "hidden" }}
                dataLength={messages?.length || 0}
                next={() => setPageSize(pageSize + 50)}
                hasMore={pageSize === messages?.length}
                loader={null}
                scrollableTarget="messages_scroll"
                inverse
              >
                {messages.map((m) => (
                  <Message key={m.id} message={m} />
                ))}
              </InfiniteScroll>
            </div>
          </div>

          {loading && (
            <div className="loaderClass">
              <Loader />
            </div>
          )}
        </section>
      </div>
    </Modal>
  );
};

export default observer(ChatQuickView);
