import firebaseApp from "firebase/compat/app";
import { SupportedLanguage } from ".";
import locale from "../constants/locale";
import { BsTextareaT } from "react-icons/bs";
import { MdOutlinePermMedia } from "react-icons/md";
import { MdOutlineQuickreply } from "react-icons/md";
import { TbLinkPlus } from "react-icons/tb";
import { FaRegListAlt } from "react-icons/fa";
import { IconType } from "react-icons";
import { CiBoxList } from "react-icons/ci";

export const whatsappTemplateLanguages = [
  "Authentication",
  "Utility",
  "Marketing",
];

export enum WhatsappTemplateActionButtonType {
  PHONE_NUMBER = "phone-number",
  WEBSITE = "website",
}

export const whatsappTemplateActionButtonTypeMap: Record<
  WhatsappTemplateActionButtonType,
  string
> = {
  [WhatsappTemplateActionButtonType.PHONE_NUMBER]: "Phone Number",
  [WhatsappTemplateActionButtonType.WEBSITE]: "Website",
};

export type WhatsappTemplateActionButton = {
  type: string;
  text: string;
  value?: string;
};

export type WhatsappTemplateQuickReplyButton = {
  text: string;
};

export enum WhatsappTemplateButtonType {
  QUICK_REPLY = "quick-reply",
  CALL_TO_ACTION = "call-to-action",
}

export const whatsappTemplateButtonTypeMap: Record<
  WhatsappTemplateButtonType,
  string
> = {
  [WhatsappTemplateButtonType.QUICK_REPLY]: "Quick reply",
  [WhatsappTemplateButtonType.CALL_TO_ACTION]: "Call to action",
};

export const whatsappTemplateButtonTypeHelpText: Record<
  WhatsappTemplateButtonType,
  null | string
> = {
  [WhatsappTemplateButtonType.CALL_TO_ACTION]:
    "Create up to 2 buttons that let customers respond to your message or take action.",
  [WhatsappTemplateButtonType.QUICK_REPLY]:
    "Create up to 3 buttons that let customers respond to your message or take action.",
};

export enum WhatsappTemplateStatus {
  PENDING = "pending",
  SUBMITTED = "submitted",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export const whatsappTemplateStatusMap: Record<WhatsappTemplateStatus, string> =
  {
    [WhatsappTemplateStatus.PENDING]: locale.pending,
    [WhatsappTemplateStatus.SUBMITTED]: locale.submittedForApproval,
    [WhatsappTemplateStatus.APPROVED]: locale.approvedByWhatsapp,
    [WhatsappTemplateStatus.REJECTED]: locale.rejectedByWhatsapp,
  };

export type WhatsappTemplate = {
  id?: string;
  name: string;
  category: string;
  messageLanguage: SupportedLanguage;
  messageBody: string;
  contentType?: ContentType;
  // Deprecated
  buttonType: WhatsappTemplateButtonType;
  // Deprecated
  actionButtons: WhatsappTemplateActionButton[];
  // Deprecated
  quickReplyButtons: WhatsappTemplateQuickReplyButton[];
  status: WhatsappTemplateStatus;
  createdAt: Date | firebaseApp.firestore.FieldValue;
  updatedAt: Date | firebaseApp.firestore.FieldValue;
  deletedAt?: Date | firebaseApp.firestore.FieldValue;
  deletedByUserId?: string;
  twilioContentSid?: string;
  metaId?: string;
  meta?: any;
  metaWhatsapp?: boolean; // template is created with Meta Whatsapp
  fieldsMapping?: {
    source: string;
    value: string;
  }[];
};

export enum ContentType {
  TEXT = "text",
  MEDIA = "media",
  QUICK_REPLY = "quick-reply",
  CTA = "cta",
  LIST_PICKER = "list-picker",
  CARD = "card",
  CATALOG = "catalog",
}

export const contentTypeMap: Record<ContentType, string> = {
  [ContentType.TEXT]: "Text",
  [ContentType.MEDIA]: "Media",
  [ContentType.QUICK_REPLY]: "Quick reply",
  [ContentType.CTA]: "Call to action",
  [ContentType.LIST_PICKER]: "List picker",
  [ContentType.CARD]: "Card",
  [ContentType.CATALOG]: "Catalog",
};

export const contentTypeSupport: Record<
  ContentType,
  {
    types: ("business" | "user")[];
    allowed: ("business" | "user")[];
  }
> = {
  [ContentType.TEXT]: {
    types: ["business", "user"],
    allowed: ["user"],
  },
  [ContentType.MEDIA]: {
    types: ["business", "user"],
    allowed: ["user"],
  },
  [ContentType.QUICK_REPLY]: {
    types: ["business", "user"],
    allowed: ["user"],
  },
  [ContentType.CTA]: {
    types: ["business", "user"],
    allowed: [],
  },
  [ContentType.LIST_PICKER]: {
    types: ["user"],
    allowed: ["user"],
  },
  [ContentType.CARD]: {
    types: ["business", "user"],
    allowed: [],
  },
  [ContentType.CATALOG]: {
    types: ["business", "user"],
    allowed: [],
  },
};

export const bodyMaxLimit: Record<ContentType, number> = {
  [ContentType.TEXT]: 1600,
  [ContentType.MEDIA]: 1600,
  [ContentType.QUICK_REPLY]: 1024,
  [ContentType.CTA]: 640,
  [ContentType.LIST_PICKER]: 1600,
  [ContentType.CARD]: 1600,
  [ContentType.CATALOG]: 1600,
};

export const contentTypeIconMap: Record<ContentType, IconType> = {
  [ContentType.TEXT]: BsTextareaT,
  [ContentType.MEDIA]: MdOutlinePermMedia,
  [ContentType.QUICK_REPLY]: MdOutlineQuickreply,
  [ContentType.CTA]: TbLinkPlus,
  [ContentType.LIST_PICKER]: CiBoxList,
  [ContentType.CARD]: FaRegListAlt,
  [ContentType.CATALOG]: FaRegListAlt,
};
