export default function LocationCard({
  payload,
}: {
  payload: {
    name?: string;
    url?: string;
    latitude: number;
    longitude: number;
  };
}) {
  // Google Maps Static API URL
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
    payload.latitude
  },${payload.longitude}&zoom=15&size=400x200&markers=color:red%7C${
    payload.latitude
  },${payload.longitude}&key=${(window as any).firebaseAppConfig.apiKey}`;

  const openInMaps = () => {
    window.open(
      `https://www.google.com/maps?q=${payload.latitude},${payload.longitude}`,
      "_blank"
    );
  };

  const openVenueUrl = (e: any) => {
    e.stopPropagation();
    window.open(payload.url, "_blank");
  };

  return (
    <div className="pt-2" style={{ maxWidth: "400px" }}>
      <div className="card-content p-0">
        {/* Map Image Container */}
        <div
          className="is-relative"
          style={{ cursor: "pointer" }}
          onClick={openInMaps}
        >
          <figure className="image is-2by1">
            <img
              src={mapUrl}
              alt={`Map location of ${payload.name}`}
              className="has-ratio"
              style={{ objectFit: "cover" }}
            />
          </figure>

          {/* Navigation Icon */}
          <button
            className="button is-white is-small"
            style={{
              position: "absolute",
              bottom: "8px",
              right: "8px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              zIndex: 1000,
            }}
            onClick={openInMaps}
          >
            <span className="icon">
              <i className="fas fa-location-arrow"></i>
            </span>
          </button>
        </div>

        {/* Location Details */}
        <div className="p-4">
          <div className="media">
            <div className="media-left">
              <span className="icon has-text-info">
                <i className="fas fa-map-marker-alt fa-lg"></i>
              </span>
            </div>
            <div className="media-content">
              <p className="title is-5">{payload.name}</p>
              <p className="subtitle is-6 has-text-grey">
                {payload.latitude.toFixed(6)}, {payload.longitude.toFixed(6)}
              </p>
            </div>
          </div>

          {payload.url && (
            <div className="mt-3">
              <a
                href={payload.url}
                className="button is-light is-primary is-small is-fullwidth"
                onClick={openVenueUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <i className="fas fa-external-link-alt"></i>
                </span>
                <span>{payload.name}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
