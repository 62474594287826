import { UploadFile } from "antd";
import { toast } from "bulma-toast";
import { useState } from "react";
import { UploadFileInterface } from "../../../auth/AvatarModal";
import Dragger from "antd/es/upload/Dragger";
import { TicketAttachment } from "../../../../models";
import firebase from "firebase/compat/app";
import { v4 as uuid } from "uuid";
import locale from "../../../../constants/locale";
import { useTranslation } from "react-i18next";

const MAX_ALLOWED_SIZE = 10 * 1024 * 1024; // 10 MB

export default function UploadAttachment({
  value,
  onChange,
  setTempFiles,
}: {
  value: TicketAttachment[];
  onChange: (attachments: TicketAttachment[]) => void;
  setTempFiles: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<TicketAttachment[]>(value);

  const handleUpload = async ({
    file,
    onSuccess,
    onError,
  }: UploadFileInterface) => {
    if (handleBeforeUpload(file)) {
      const fileId = uuid();

      try {
        setFileList((prev) => {
          const ref = [...prev];
          ref.push({
            uid: fileId,
            name: file?.name,
            status: "uploading",
            url: "",
            type: file.type,
            createdAt: new Date(),
          });

          return ref;
        });

        const ref = firebase.storage().ref(`files`);

        const fileRef = ref.child(fileId);
        await fileRef.put(file);
        const url = await fileRef.getDownloadURL();

        setFileList((prev) => {
          const ref = [...prev];

          const index = ref.findIndex((item) => item.uid === fileId);
          if (index > -1) {
            ref[index].status = "done";
            ref[index].url = url;
          }
          onChange(ref);
          return ref;
        });

        setTempFiles((prev) => [...prev, fileId]);

        onSuccess(url, file);
      } catch (errors) {
        onError(errors);
        setFileList((prev) => {
          const ref = [...prev];

          const index = ref.findIndex((item) => item.uid === fileId);
          if (index > -1) {
            ref[index].status = "error";
          }
          return ref;
        });
      }
    }
  };

  const handleBeforeUpload = (file: UploadFile) => {
    if (
      ![
        "audio/mpeg",
        "audio/mp3",
        "audio/ogg",
        "audio/amr",
        "image/jpeg",
        "image/png",
        "video/mp4",
        "application/pdf",
      ].includes(file.type!)
    ) {
      toast({
        message: "File type is not supported",
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
      return false;
    }

    if ((file?.size || 0) > MAX_ALLOWED_SIZE) {
      toast({
        message: "File must smaller than 10MB!",
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
      return false;
    }

    return true;
  };

  const handleRemoveFile = async (file: any) => {
    try {
      const ref = firebase.storage().ref(`files`);

      const fileRef = ref.child(file.uid);
      await fileRef.delete();
    } catch (error) {
      console.error(error);
      toast({
        message: "Something went wrong",
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
    }

    setFileList((prev) => {
      const ref = [...prev];
      const index = ref.findIndex((item) => item.uid === file.uid);

      if (index > -1) {
        ref.splice(index, 1);
      }
      onChange(ref);
      return ref;
    });
  };

  return (
    <div>
      <Dragger
        className="tw-bg-white tw-w-[350px]"
        name="files"
        listType="picture"
        fileList={fileList as UploadFile[]}
        customRequest={handleUpload as any}
        onRemove={handleRemoveFile}
        multiple={false}
      >
        <p className="ant-upload-drag-icon">
          <svg
            viewBox="0 0 1024 1024"
            focusable="false"
            data-icon="inbox"
            width="50"
            height="50"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0060.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
          </svg>
        </p>
        <p className="ant-upload-text">{t(locale.uploadAttachmentText)}</p>
      </Dragger>
    </div>
  );
}
