import { observer } from "mobx-react-lite";
import { useStore } from "../../stores";
import { useCallback, useEffect, useState } from "react";
import { loadScript } from "../../utils/scripts";
import IncomingCallModal from "./modals/IncomingCallModal";
import VoiceCallModal from "./modals/VoiceCallModal";

export const TwilioVoiceCallProvider = observer(() => {
  const { selectedBot } = useStore("bots");

  if (
    !selectedBot ||
    !selectedBot.settings?.voiceCallEnabled ||
    selectedBot.settings?.voiceCallProvider !== "twilio"
  ) {
    return null;
  }

  return <Init />;
});

const log = (msg: string) => {
  console.log("Twilio->SDK " + msg);
};

const Init = observer(() => {
  const {
    getTwilioToken,
    twilioCallDevice,
    setTwilioCallDevice,
    voiceCallMeta,
    setVoiceCallMeta,
  } = useStore("bots");
  const [incomingCall, setIncomingCall] = useState<
    | {
        from: string;
        conn: any;
      }
    | undefined
  >();

  const attachListeners = useCallback(
    (token: string) => {
      log("Got a token.");
      console.log("Token: " + token);

      // Setup Twilio.Device
      //@ts-ignore
      const device = new Twilio.Device(token, {
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
        codecPreferences: ["opus", "pcmu"],
        // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
        // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
        // a second time and sending the tone twice. This will be default in 2.0.
        fakeLocalDTMF: true,
        // Use `enableRingingState` to enable the device to emit the `ringing`
        // state. The TwiML backend also needs to have the attribute
        // `answerOnBridge` also set to true in the `Dial` verb. This option
        // changes the behavior of the SDK to consider a call `ringing` starting
        // from the connection to the TwiML backend to when the recipient of
        // the `Dial` verb answers.
        enableRingingState: true,
        debug: true,
      });

      device.on("ready", function () {
        log("Twilio.Device Ready!");
      });

      device.on("error", function (error: any) {
        log("Twilio.Device Error: " + error.message);
      });

      device.on("connect", function (conn: any) {
        log("Successfully established call ! ");
        setVoiceCallMeta({
          callerId: conn?.parameters?.From ?? "Unknown",
          mode: "inbound",
          inProgress: true,
          conn: conn,
        });
      });

      device.on("disconnect", function (conn: any) {
        console.log("conn", conn);

        log("Call ended.");
        setVoiceCallMeta(undefined);
      });

      device.on("incoming", function (conn: any) {
        console.log(conn.parameters);
        log("Incoming connection from " + conn.parameters.From);
        setIncomingCall({
          from: conn.parameters.From,
          conn,
        });
      });
      setTwilioCallDevice(device);
    },
    [setTwilioCallDevice, setVoiceCallMeta]
  );

  const initTwilio = useCallback(async () => {
    const token = await getTwilioToken();
    console.log("Twilio token", token);
    loadScript(
      `https://media.twiliocdn.com/sdk/js/client/v1.8/twilio.min.js`,
      "twilio-sdk-js",
      () => {
        if ((window as any).Twilio) {
          attachListeners(token);
        } else {
          // If not available, wait and try again
          setTimeout(() => {
            if ((window as any).Twilio) {
              attachListeners(token);
            }
          }, 1000);
        }
      }
    );
  }, [attachListeners, getTwilioToken]);

  useEffect(() => {
    initTwilio();
  }, [initTwilio]);

  if (incomingCall?.from) {
    return (
      <IncomingCallModal
        payload={{
          from: incomingCall.from,
        }}
        onAccept={() => {
          log("Accepted call ...");
          incomingCall.conn.accept();
          setIncomingCall(undefined);
        }}
        onReject={() => {
          log("Rejected call ...");
          incomingCall.conn.reject();
          setIncomingCall(undefined);
        }}
      />
    );
  }

  if (voiceCallMeta?.callerId) {
    return (
      <VoiceCallModal
        isInProgress={voiceCallMeta?.inProgress}
        mode={voiceCallMeta.mode}
        callerId={voiceCallMeta.callerId}
        onHangUp={() => {
          console.log("Hanging up...", twilioCallDevice);
          if (twilioCallDevice) {
            twilioCallDevice.disconnectAll();
          }
          setVoiceCallMeta(undefined);
        }}
      />
    );
  }

  return null;
});
