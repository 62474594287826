import { useTranslation } from "react-i18next";

import { FallbackImage } from "../../../components";

import locale from "../../../constants/locale";

type Props = {
  title?: string;
  image_url?: string;
  subtitle?: string;
  buttons?: { type: string; title: string; payload: string }[];
};

const GenericSlide = ({
  title,
  image_url,
  subtitle,
  buttons,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="card" style={{ maxWidth: 300 }} {...rest}>
      {!!image_url && (
        <div className="card-image">
          <figure className="image is-4by3">
            <FallbackImage
              src={image_url}
              alt="Placeholder"
              customFallback={<span>{t(locale.resourceNotFound)}</span>}
            />
          </figure>
        </div>
      )}
      <div className="card-content">
        {!!title && <p className="title is-6">{title}</p>}
        {!!subtitle && <p className="subtitle is-6">{subtitle}</p>}
      </div>
      {!!buttons?.length && (
        <footer className="card-footer">
          {buttons.map((m) => (
            <p key={m.title} className="card-footer-item">
              <span>{m.title}</span>
            </p>
          ))}
        </footer>
      )}
    </div>
  );
};

export default GenericSlide;
