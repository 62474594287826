import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroll-component";
import clsx from "clsx";
import Message from "./components/Message";
import styles from "./Conversation.module.scss";
import MessageComposer from "./components/MessageComposer";
import { useStore } from "../../stores";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import { FallbackImage, Loader } from "..";
import { EdnaChatInfo, EdnaChatType } from "../../models/EdnaChat";
import ChatInfo from "./components/ChatInfo";
import { useHistory, useLocation } from "react-router";
import ThreadConversation from "./ThreadConversation";

const Conversation = ({
  hideHeader,
  chatInfo,
}: {
  hideHeader?: boolean;
  chatInfo?: EdnaChatInfo;
}) => {
  const { selectedChat, messages, loadMessages } = useStore("ednaChats");

  const { threadOpen, firstOrCreateChat } = useStore("ednaThreadChats");

  const divRef = useRef<HTMLDivElement | null>(null);
  const [showInfo, setShowInfo] = useState(false);
  const [pageSize, setPageSize] = useState(200);
  const [lastMsg, setLastMsg] = useState("");
  const isMobile = useMobileResponsive();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get("thread");

  useEffect(() => {
    if (selectedChat && threadId && !threadOpen) {
      firstOrCreateChat(
        String(selectedChat.room),
        String(selectedChat.id),
        threadId
      );
    }
  }, [selectedChat, firstOrCreateChat, threadId, threadOpen]);

  useEffect(() => {
    if (!selectedChat?.id || !chatInfo?.id) {
      return;
    }

    setLoading(true);

    const load = async () => {
      await loadMessages(pageSize);
      setLoading(false);
    };

    load();

    const handle = setInterval(load, 15000, false);

    return () => clearInterval(handle);
  }, [loadMessages, pageSize, selectedChat?.id, chatInfo?.id]);

  useEffect(() => {
    if (
      loading ||
      !divRef.current ||
      !messages?.length ||
      lastMsg === messages[0]?.id
    ) {
      return;
    }

    setLastMsg(messages[0].id || "");

    divRef.current.scrollTop = divRef.current.scrollHeight + 100;
  }, [loading, lastMsg, messages, messages?.length]);

  if (loading || !chatInfo) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <section
        id="section-edna-conversation"
        className={clsx(
          "full-screen-mobile is-flex is-flex-grow-1 is-flex-direction-column has-border-right",
          styles.container,
          {
            "pb-3 chat-bg": !isMobile,
            "is-active": !showInfo && !threadOpen,
          }
        )}
      >
        {!hideHeader && (
          <div
            className={clsx("has-background-light has-border", styles.header)}
          >
            <div className="is-flex is-align-items-center">
              {isMobile && (
                <button
                  className="button is-ghost no-focus"
                  onClick={() => history.goBack()}
                >
                  <span className="icon">
                    <img src="/assets/chevron-left.svg" alt="" />
                  </span>
                </button>
              )}
              {chatInfo?.profileUrl && (
                <figure className="is-flex-shrink-0 mr-4">
                  <p className="image is-32x32 is-square">
                    <FallbackImage
                      className="is-rounded"
                      src={chatInfo.profileUrl}
                      fallback={"/assets/avatar.svg"}
                      alt={chatInfo.name}
                      title={chatInfo.name}
                    />
                  </p>
                </figure>
              )}
              <h4 className="title is-6 mb-0">{chatInfo?.name}</h4>
            </div>
            <button
              className={clsx(styles.noBtn, {
                "has-text-primary": showInfo,
              })}
              onClick={() => setShowInfo(!showInfo)}
            >
              <span className="icon">
                <img src="/assets/three-dots.svg" alt="" />
              </span>
            </button>
          </div>
        )}

        <div className="is-flex px-4 is-flex-direction-column is-align-items-stretch is-justify-content-flex-end is-flex-grow-1 is-relative">
          <div
            ref={divRef}
            id="messages_scroll"
            className={clsx(styles.scrollContainer)}
          >
            <InfiniteScroll
              style={{ overflowX: "hidden" }}
              dataLength={messages?.length || 0}
              next={() => setPageSize(pageSize + 50)}
              hasMore={pageSize === messages?.length}
              loader={null}
              scrollableTarget="messages_scroll"
              className="is-flex is-flex-direction-column-reverse"
              inverse
            >
              {messages.map((m) => (
                <Message
                  disableThreadReply={
                    ![EdnaChatType.SINGLE, EdnaChatType.CHANNEL].includes(
                      chatInfo.type
                    )
                  }
                  key={m.id}
                  message={m}
                  chatInfo={chatInfo}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
        <MessageComposer />
      </section>
      {showInfo && (
        <ChatInfo chatInfo={chatInfo} onClose={() => setShowInfo(false)} />
      )}
      {threadOpen && <ThreadConversation />}
    </>
  );
};

export default observer(Conversation);
