import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FallbackImage, FallbackVideo } from "../../../components";

import locale from "../../../constants/locale";
import { getContentType } from "../../../services/http";

type Props = {
  url: string;
  setImageToShow?: (url: string) => void;
};

const InstagramStory = ({ url, setImageToShow }: Props) => {
  const { t } = useTranslation();
  const [type, setType] = useState("");

  useEffect(() => {
    getContentType(url).then((type) => {
      if (type) {
        setType(type);
      } else {
        setType("unknown");
      }
    });
  }, [url]);

  if (!type) {
    return null;
  }

  const customFallback = <span>{t(locale.storyDeletedOrExpired)}</span>;

  if (type.includes("video")) {
    return (
      <FallbackVideo
        className="has-ratio"
        src={url}
        controls
        customFallback={customFallback}
        renderer={(e) => (
          <div className="image is-9by16 has-rounded-corners">{e}</div>
        )}
      />
    );
  }

  return (
    <FallbackImage
      src={url}
      alt="media"
      customFallback={customFallback}
      renderer={(e) => (
        <div
          className="image is-9by16 has-rounded-corners is-clickable"
          onClick={() => setImageToShow && setImageToShow(url)}
        >
          {e}
        </div>
      )}
    />
  );
};

export default InstagramStory;
