import { useTranslation } from "react-i18next";
import { Ticket, ticketStatusMap } from "../../../../models";
import { parseFirebaseDateTime } from "../../../../utils/internationalization";
import { useMemo } from "react";
import { useStore } from "../../../../stores";
import { observer } from "mobx-react-lite";

const Histories = ({ ticket }: { ticket?: Ticket }) => {
  const { t } = useTranslation();
  const { users } = useStore("users");

  const user = useMemo(
    () => users?.find((u) => u.id === ticket?.userId),
    [users, ticket?.userId]
  );

  return (
    <>
      <ul>
        <li>
          Created by <b>{user?.name ?? "Workflow"}</b> at{" "}
          <b>{parseFirebaseDateTime(ticket?.createdAt)}</b>
        </li>
        {ticket?.histories?.map((f, i) => (
          <li key={i}>
            <span>
              Move to <b>{t(ticketStatusMap[f.currentStatus])}</b> at{" "}
              <b>{parseFirebaseDateTime(f.addedAt)}</b>
              {f.removedAt ? (
                <>
                  {" "}
                  and removed at <b>{parseFirebaseDateTime(f.removedAt)}</b>
                </>
              ) : (
                ""
              )}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default observer(Histories);
