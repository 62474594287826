import React from "react";
import { FaClock, FaPhoneAlt } from "react-icons/fa";

// Define call status types
type CallStatus =
  | "ringing"
  | "completed"
  | "no-answer"
  | "busy"
  | "failed"
  | "in-progress";

// Define the payload interface
interface VoiceCallPayload {
  from: string;
  to: string;
  duration: number;
  status: CallStatus;
  direction?: "inbound" | "outbound";
}

// Props interface
interface VoiceCallCardProps {
  payload: VoiceCallPayload;
}

// Status color mapping type
type StatusColorMap = {
  [key in CallStatus]: string;
};

const formatDuration = (seconds: number): string => {
  if (!seconds || seconds === 0) return "No duration";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const statusColors: StatusColorMap = {
  ringing: "#ca8a04", // blue-600
  completed: "#16a34a", // green-600
  "no-answer": "#dc2626", // red-600
  busy: "#ca8a04", // yellow-600
  failed: "#dc2626", // red-600
  "in-progress": "#2563eb", // blue-600
};

const getStatusColor = (status: CallStatus): string => {
  return statusColors[status.toLowerCase() as CallStatus] || "#4b5563"; // gray-600 as default
};

const VoiceCallCard: React.FC<VoiceCallCardProps> = ({ payload }) => {
  const statusColor = getStatusColor(payload.status);

  return (
    <div
      style={{
        maxWidth: "28rem",
        borderRadius: "0.5rem",
      }}
    >
      <div style={{ padding: ".5rem" }}>
        {/* Call Status Header */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <FaPhoneAlt color={statusColor} size={20} />
            <span
              style={{
                fontWeight: 500,
                color: statusColor,
              }}
            >
              {payload.status}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <FaClock
              color={payload?.direction === "outbound" ? "white" : "#6b7280"}
              size={16}
            />
            <span
              style={{
                fontSize: "0.875rem",
                color: payload?.direction === "outbound" ? "white" : "#4b5563",
              }}
            >
              {formatDuration(payload.duration)}
            </span>
          </div>
        </div>

        {/* Phone Numbers */}
        <div style={{ marginTop: "0.75rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "0.75rem",
            }}
          >
            <span
              style={{
                fontSize: "0.875rem",
                color: payload?.direction === "outbound" ? "white" : "#6b7280",
              }}
            >
              From
            </span>
            <span style={{ fontWeight: 500 }}>{payload.from}</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                fontSize: "0.875rem",
                color: payload?.direction === "outbound" ? "white" : "#6b7280",
              }}
            >
              To
            </span>
            <span style={{ fontWeight: 500 }}>{payload.to}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceCallCard;
