/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, MenuProps } from "antd";
import { IoChevronDown } from "react-icons/io5";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useStore } from "../../../stores";
import { useState } from "react";
import TicketModal from "../../../pages/chats/Tickets/components/TicketModal";
import { EdnaMessageParser } from "../../../models";
import ReactDOMServer from "react-dom/server";
import Mentionify from "../../mentionify/Mentionify";

export default function ActionDropdown({
  disableThreadReply,
  messageId,
  isFromUser,
  message,
}: {
  disableThreadReply?: boolean;
  messageId: string;
  isFromUser: boolean;
  message: EdnaMessageParser;
}) {
  const { selectedChat } = useStore("ednaChats");
  const { firstOrCreateChat } = useStore("ednaThreadChats");
  const { t } = useTranslation();
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [parsedDescription, setParsedDescription] = useState("");

  if (!selectedChat?.id) return null;

  const items: MenuProps["items"] = [
    {
      key: locale.replyInThread,
      label: t(locale.replyInThread),
      onClick: () =>
        firstOrCreateChat(
          String(selectedChat?.room),
          String(selectedChat?.id),
          messageId
        ),
    },
    {
      key: locale.createTicket,
      label: t(locale.createTicket),
      onClick: () => {
        try {
          const html = ReactDOMServer.renderToString(
            <Mentionify>{message.text}</Mentionify>
          );
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const textContent = doc.body.textContent || "";
          setParsedDescription(textContent.trim());
        } catch (e) {}
        setOpenTicketModal(true);
      },
    },
  ].filter((item) => !disableThreadReply || item.key !== locale.replyInThread);

  if (!items.length) return null;

  return (
    <div
      style={{
        position: "absolute",
        right: "0",
        top: "0",
      }}
      className="px-2 py-1"
    >
      <Dropdown menu={{ items }}>
        <a
          onClick={(e) => e.preventDefault()}
          className={clsx({
            "has-text-black": isFromUser,
            "has-text-white": !isFromUser,
          })}
        >
          <IoChevronDown />
        </a>
      </Dropdown>
      {openTicketModal && (
        <TicketModal
          mode={"add"}
          isOpen={openTicketModal}
          onClose={() => setOpenTicketModal(false)}
          ticket={
            {
              description: parsedDescription,
            } as any
          }
        />
      )}
    </div>
  );
}
