import { useEffect, useState } from "react";
//@ts-ignore
import vCard from "vcf";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import { DownloadButton } from "../../../components";
import clsx from "clsx";
import { useStore } from "../../../stores";

export default function VcfContact({
  src,
  invertColors,
}: {
  src: string;
  invertColors: boolean;
}) {
  const { t } = useTranslation();
  const { getBearerToken } = useStore("auth");
  const { selectedWorkspace } = useStore("workspaces");
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState<any>(null);
  const [error, setError] = useState<any>(false);

  useEffect(() => {
    const fetchVCF = async () => {
      if (!selectedWorkspace?.id) {
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${selectedWorkspace?.id}/parse-vcf`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getBearerToken()}`,
            },
            body: JSON.stringify({ url: src }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch VCF file");
        }

        const { data } = await response.json();

        const vcf = new vCard().parse(data);
        setContact(vcf.toJSON());
        setLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing VCF file:", error);
        setError(true);
      }
    };

    fetchVCF();
  }, [getBearerToken, selectedWorkspace?.id, src]);

  if (!selectedWorkspace) {
    return null;
  }

  if (error) {
    return (
      <div className="contact-card">
        <h1
          className={clsx("title is-6", {
            "has-text-white": invertColors,
          })}
        >
          {t(locale.contactLoadingError)}
        </h1>
        <DownloadButton
          className={clsx("mt-2", { "has-text-white-bis": invertColors })}
          src={src}
          name={t(locale.download)}
        />
      </div>
    );
  }

  const mappings = {
    fn: t(locale.name),
    tel: t(locale.phone),
  };

  return (
    <div className="contact-card">
      <h1
        className={clsx("title has-text-weight-semibold is-6 mb-2", {
          "has-text-white": invertColors,
        })}
      >
        {t(locale.contact)}
      </h1>
      {loading ? (
        <p>{t(locale.loading)}...</p>
      ) : (
        <div>
          {contact[1]
            ?.filter((f: any) => ["fn", "tel"].includes(f[0]))
            ?.map((c: any) => (
              <p className="ml-1">
                <span className={invertColors ? "" : "has-text-black"}>
                  {mappings[c[0] as never] ?? c[0]}:
                </span>{" "}
                <span className="ml-1">{c[3]}</span>
              </p>
            ))}

          {!invertColors && (
            <DownloadButton
              className={clsx("mt-2", { "has-text-white-bis": invertColors })}
              src={src}
              name={t(locale.download)}
            />
          )}
        </div>
      )}
    </div>
  );
}
